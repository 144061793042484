import { datadogLogs } from '@datadog/browser-logs'

import { api } from '@services/api'
import { datadogMessages } from '@constants/datadog'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { threeStepsFeesEvaluator } from '@utils/threeStepsFeeEvaluator'
import { HttpError } from '@errors/httpError'
import { claimCodePaths, orderSteps } from '@configs/urls'
import { rangeErrors } from '@constants/shopResponseErrors'
import { generatePath, NavigateFunction } from 'react-router-dom'
import { evaluateLimits, LimitsViolations } from '@utils/evaluateLimits'
import { CartContext } from '@context/cart'
import { MainKeyContextType } from '@context/mainKey'
import { ProductsContext } from '@context/products'
import { SettingsContext } from '@context/settings'
import { ReferFriendPanelStatus } from '@typeDeclarations/referFriend'
import { UserContext } from '@context/user'
import { FormikHelpers } from 'formik'

const REDIRECT_TIMEOUT = 3000

type CartSubmitHandlerParameters = {
  clearCart: CartContext['clearCart']
  getOrderId: CartContext['getOrderId']
  isMainChoiceCardEmpty: CartContext['isMainChoiceCardEmpty']
  checkIfChoiceCardBalancesUpToDate: CartContext['checkIfChoiceCardBalancesUpToDate']
  saveCart: CartContext['saveCart']
  setOrderId: CartContext['setOrderId']

  mainKey: MainKeyContextType['mainKey']

  reloadProducts: ProductsContext['reloadProducts']

  panel: SettingsContext['panel']
  referralCode: SettingsContext['referralCode']
  setPanel: SettingsContext['setPanel']
  setReferralCode: SettingsContext['setReferralCode']

  email: UserContext['email']

  setOrderSubmitted: React.Dispatch<React.SetStateAction<boolean>>
  setBalanceNotValid: React.Dispatch<React.SetStateAction<boolean>>
  setViolationError: React.Dispatch<React.SetStateAction<LimitsViolations | undefined>>
  setError: React.Dispatch<React.SetStateAction<string | boolean | undefined>>

  persistForm: React.MutableRefObject<boolean>

  navigate: NavigateFunction
}

export const onSubmit =
  ({
    clearCart,
    email,
    getOrderId,
    mainKey,
    navigate,
    panel,
    persistForm,
    referralCode,
    reloadProducts,
    saveCart,
    setBalanceNotValid,
    setError,
    setOrderId,
    setOrderSubmitted,
    setPanel,
    setReferralCode,
    setViolationError,
    checkIfChoiceCardBalancesUpToDate,
  }: CartSubmitHandlerParameters) =>
  async (values: CheckoutFormikValues, { setSubmitting }: FormikHelpers<CheckoutFormikValues>) => {
    const referralProgramEnabled = panel?.status === ReferFriendPanelStatus.Active

    if (!mainKey) return

    setOrderSubmitted(true)

    const orderId = getOrderId()

    if (orderId) {
      datadogLogs.logger.info(datadogMessages.attemptedOrderWhenOrderIdPresent)
      return
    }

    try {
      const violations = await evaluateLimits(values, mainKey)
      if (violations.violated) {
        setViolationError(violations)
        setOrderSubmitted(false)
        return
      }

      persistForm.current = true // deprecated
      setError(false)

      const communication_email = values.email.trim().toLowerCase()

      const {
        payment_method,
        primary_claim_code,
        products_with_fees,
        thirdResponse,
        used_giftcards,
      } = await threeStepsFeesEvaluator(values, mainKey)

      const balancesChanged = await checkIfChoiceCardBalancesUpToDate()

      if (balancesChanged) {
        setSubmitting(false)

        setOrderSubmitted(false)
        setBalanceNotValid(true)

        return
      }

      // total, as payment processing fee is added by mollie
      // the value total_with_fee we get from the backend is just to show on the ui
      const payment_amount = thirdResponse.payment_fee.total

      const result = await api.shopOrder({
        communication_email,
        payment_amount,
        payment_method,
        primary_claim_code,
        purchased_products: products_with_fees,
        used_giftcards,
      })

      const cart = setOrderId(result.pk)
      saveCart(cart)

      if (referralProgramEnabled && !referralCode) {
        try {
          const referralEmail = email || communication_email
          const response = await api.createReferralCode(mainKey, referralEmail)

          setReferralCode(response.referral_code)
          setPanel(response.panel)
        } catch (e) {
          console.warn('[Referral] Cannot get referral code', e)
        }
      }

      const paymentUrl = result.payment?.payment_url

      if (!paymentUrl) {
        datadogLogs.logger.info(datadogMessages.cardsOrderedPaymentless)
        navigate(generatePath(orderSteps.orderSummary, { id: result.pk }))
        return
      }

      datadogLogs.logger.info(datadogMessages.paymentStarted)
      window.location.href = paymentUrl
    } catch (e) {
      setOrderSubmitted(false)
      persistForm.current = false

      if (e instanceof HttpError && e.json !== null && typeof e.json === 'object') {
        const info = e.json
        if ('detail' in info && typeof info.detail === 'string' && info.detail in rangeErrors) {
          setError(info.detail)
          await reloadProducts()
          clearCart()
          setTimeout(
            () => navigate(generatePath(claimCodePaths.root, { claimCode: mainKey })),
            REDIRECT_TIMEOUT,
          )
          return
        }
      }
      setError(e?.toString())
      console.error('Error proceeding to payment', e)
    }
  }
