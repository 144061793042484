import { fetchAuthSession } from 'aws-amplify/auth'
import { RequestInitRetryParams } from 'fetch-retry'
import i18next from 'i18next'

import { envs } from '@configs/envs'
import { datadogLogs } from '@datadog/browser-logs'

type GetConfigOptions = {
  noAuth?: boolean
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  headers?: Record<string, string>
  body?: string
  languageCode?: string
}

type RetryOptions = RequestInitRetryParams<typeof window.fetch>

const countryCodeToLanguageCode = [
  ['dk', 'da'],
  ['se', 'sv'],
  ['ee', 'et'],
]

const getJwtToken = async () => {
  try {
    const session = await fetchAuthSession()
    return session.tokens?.idToken?.toString()
  } catch {
    return null
  }
}

export const getFetchConfig = async ({
  body,
  languageCode,
  method,
  noAuth,
  headers = {},
  retries,
  retryDelay,
  retryOn,
}: GetConfigOptions & RetryOptions = {}): Promise<RequestInit & RetryOptions> => {
  const token = await getJwtToken()
  const authorization =
    noAuth || !token ? { authorization: '' } : { authorization: `Bearer ${token}` }
  const channel = envs.VITE_API_CHANNEL
  const nonStandardLanguageCode = languageCode ?? i18next.language
  const isoLanguageCode =
    countryCodeToLanguageCode.find(
      ([countryCode]) => countryCode === nonStandardLanguageCode,
    )?.[1] ?? nonStandardLanguageCode
  const ddSessionId = datadogLogs.getInternalContext()?.session_id ?? null

  return {
    headers: {
      'content-type': 'application/json',
      channel: channel ? channel : '',
      'accept-language': isoLanguageCode,
      ...authorization,
      ...(ddSessionId && { 'X-Session-ID': ddSessionId }),
      ...headers,
    },
    method: method ?? 'GET',
    body,
    retries,
    retryDelay,
    retryOn,
  }
}

export const getVoucherFetchConfig = async ({
  body,
  languageCode,
  method,
  headers = {},
  retries,
  retryDelay,
  retryOn,
}: GetConfigOptions & RetryOptions = {}): Promise<RequestInit & RetryOptions> => {
  const authorization = { authorization: '' }
  const nonStandardLanguageCode = languageCode ?? i18next.language
  const isoLanguageCode =
    countryCodeToLanguageCode.find(
      ([countryCode]) => countryCode === nonStandardLanguageCode,
    )?.[1] ?? nonStandardLanguageCode

  return {
    headers: {
      'content-type': 'application/json',
      'accept-language': isoLanguageCode,
      ...authorization,
      ...headers,
    },
    method: method ?? 'GET',
    body,
    retries,
    retryDelay,
    retryOn,
  }
}
