export const shop_order_survey_id = '4b9f3b1d-0248-4254-8fe4-939bb8d72feb'
export const shop_order_object_type = 'shop_order'

export const panel_survey_id = 'eed0faf6-4c22-4372-825c-bc3bf51a893c'
export const panel_object_type = 'panel'

const surveys = {
  shop_order: { survey_id: shop_order_survey_id, object_type: shop_order_object_type },
  panel: { survey_id: panel_survey_id, object_type: panel_object_type },
}

export type AvailableSurveys = keyof typeof surveys

export const getSurveyConfig = (type: AvailableSurveys) => ({
  survey_id: surveys[type].survey_id,
  object_type: surveys[type].object_type,
})
