import Fraction from 'fraction.js'
import { api } from '@services/api'

import { CalculateFeesResponse } from '@typeDeclarations/api/calculateFees'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { determineIfDeducted } from './determineIfDeducted'

type EvaluatedFees = {
  thirdResponse: CalculateFeesResponse
  primary_claim_code: string
  payment_method: '' | 'ideal' | 'creditcard' | null
  used_giftcards: Array<{
    claim_code: string
    debit_value: number
  }>
  products_with_fees: Array<{
    value: number
    amount: number
    product: number
    used_fee_value: number
  }>
}

export const threeStepsFeesEvaluator = async (
  formValues: CheckoutFormikValues,
  mainKey: string | null,
): Promise<EvaluatedFees> => {
  const f = (x: number) => new Fraction(x)

  const primary_claim_code = mainKey || ''

  const payment_method = formValues?.paymentMethod || null
  const purchased_products_from_form = formValues?.cards?.map((card) => ({
    value: card.amount,
    amount: card.quantity,
    product: Number(card.id),
    used_fee_value: 0,
  }))

  // blank state lets you get fee values from backend
  // for individual cards
  const firstResponse = await api.calculateFees({
    payment_method,
    purchased_products: purchased_products_from_form,
    used_giftcards: [{ debit_value: 0, claim_code: primary_claim_code }],
    primary_claim_code,
  })

  // we use returned values to add fees to individual cards
  const purchased_products_with_fees = formValues.cards.map((card, idx) => {
    const isDeducted = determineIfDeducted(card.feePaymentOption, card.denominatedValues)

    return {
      value: isDeducted
        ? f(card.amount).sub(firstResponse.products_with_fees[idx].fee).valueOf()
        : card.amount,
      amount: card.quantity,
      product: Number(card.id),
      used_fee_value: isDeducted ? firstResponse.products_with_fees[idx].fee : 0,
    }
  })

  // second call lets you get correct total order value if there were no choice cards used
  // this lets you conclude how to debit choice cards

  const secondResponse = await api.calculateFees({
    payment_method,
    purchased_products: purchased_products_with_fees,
    used_giftcards: [{ debit_value: 0, claim_code: primary_claim_code }],
    primary_claim_code,
  })

  let totalWithFees = secondResponse.order_value.total_amount_with_product_fee

  const used_giftcards = formValues.choiceCardsToUse
    .map(({ claim_code, debit_value }) => ({
      claim_code,
      debit_value,
    }))
    .map(({ claim_code, debit_value }) => {
      const middle = Math.max(f(totalWithFees).sub(debit_value).valueOf(), 0)
      const deducted = f(totalWithFees).sub(middle).valueOf()
      totalWithFees = f(totalWithFees).sub(deducted).valueOf()

      return { claim_code, debit_value: deducted }
    })

  // with correct individual cards with fees
  // and used choice cards
  // we get correct final amounts to pay

  const thirdResponse = await api.calculateFees({
    payment_method,
    purchased_products: purchased_products_with_fees,
    used_giftcards,
    primary_claim_code,
  })

  return {
    payment_method,
    primary_claim_code,
    products_with_fees: purchased_products_with_fees,
    thirdResponse,
    used_giftcards,
  }
}
