import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { claimCodePaths } from '@configs/urls'
import { datadogRum } from '@datadog/browser-rum'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useSettingsContext } from '@hooks/useSettings'
import { useUserContext } from '@hooks/useUserContext'
import { api } from '@services/api'
import { Flows } from '@services/api.types'
import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { HashType } from '@typeDeclarations/hashType'
import { checkOwnerMismatch } from '@utils/checkOwnerMismatch'

const ProcessCode: React.FC = () => {
  const { dispatchHashFlows } = useHashFlowContext()
  const { isLoggedIn, logOut } = useUserContext()
  const { claimCode = '' } = useParams()
  const navigate = useNavigate()
  const { setAllBranding, setDefaultBranding } = useSettingsContext()
  const [error, setError] = useState(false)

  useEffect(() => {
    const checkMismatchedOwner = async (claimCode: string) => {
      const ownerMismatch = await checkOwnerMismatch(claimCode)
      if (ownerMismatch) await logOut()
      return ownerMismatch
    }

    const checkFlow = async (claimCode: string) => {
      const cardFlow = await api.checkCardFlow(claimCode)
      const flow = cardFlow.flow

      if (!cardFlow.shop_personalization) {
        setDefaultBranding()
      } else {
        setAllBranding(cardFlow)
      }

      const preloadSrc = cardFlow.shop_personalization?.image_welcome
      if (preloadSrc) {
        const preload = new Image()
        preload.src = preloadSrc
      }

      dispatchHashFlows({
        type: HashFlowActions.SetHashFlow,
        payload: { hash: claimCode, flow, type: HashType.ClaimCode },
      })

      datadogRum.setGlobalContextProperty('claimCode', {
        claimCode,
        flow,
      })

      switch (flow) {
        case Flows.ShopAccountMandatory:
          if (!isLoggedIn) navigate(generatePath(claimCodePaths.account, { claimCode }))
          if (isLoggedIn) {
            const mismatchedOwner = await checkMismatchedOwner(claimCode)
            if (mismatchedOwner) {
              navigate({ pathname: generatePath(claimCodePaths.account, { claimCode }) })
              return
            }
            navigate(generatePath(claimCodePaths.welcome, { claimCode }))
          }
          return
        case Flows.ShopAccountOptional:
          if (!cardFlow.is_owned && cardFlow.claim_skipped) {
            navigate(generatePath(claimCodePaths.welcome, { claimCode }))
            return
          }
          if (!isLoggedIn) navigate(generatePath(claimCodePaths.account, { claimCode }))
          if (isLoggedIn) {
            const mismatchedOwner = await checkMismatchedOwner(claimCode)
            if (mismatchedOwner) {
              navigate({ pathname: generatePath(claimCodePaths.account, { claimCode }) })
              return
            }
            navigate(generatePath(claimCodePaths.welcome, { claimCode }))
          }
          return
        case Flows.ShopGuest:
          if (!isLoggedIn) navigate(generatePath(claimCodePaths.welcome, { claimCode }))
          if (isLoggedIn) {
            await logOut()
            navigate(generatePath(claimCodePaths.welcome, { claimCode }))
          }
          return
      }
    }

    checkFlow(claimCode).catch((e: unknown) => {
      console.error('Something went wrong while evaluating which flow to use:', e)
      setError(true)
    })

    if (error) throw Error('Something went wrong')
  }, [
    claimCode,
    dispatchHashFlows,
    error,
    isLoggedIn,
    logOut,
    navigate,
    setAllBranding,
    setDefaultBranding,
  ])

  return <div>Processing...</div>
}

export default ProcessCode
