import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const Global: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [pathname])

  return <>{children}</>
}
