import { PropsWithChildren, createContext } from 'react'

import { useSavedState } from '@hooks/useSavedState'
import { getLocalStorageKey } from '@utils/getLocalStorageKey'

export type MainKeyContextType = {
  mainKey: string | null
  setMainKey: (newValue: string | null) => void
}

const defaultCtx = { mainKey: null, setMainKey: () => {} }

export const MainKeyContext = createContext<MainKeyContextType>(defaultCtx)

type Props = {
  values?: { initialMainKey?: string }
}

export const MainKeyContextProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  values = {},
}) => {
  const { initialMainKey } = values
  const init = initialMainKey ? initialMainKey : undefined
  const [mainKey, setMainKey] = useSavedState(getLocalStorageKey('main_key'), init)

  return (
    <MainKeyContext.Provider value={{ mainKey, setMainKey }}>{children}</MainKeyContext.Provider>
  )
}
