import { BasicErrorComponent } from '@components/atoms/Error/Error'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import * as y from 'yup'
import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { shareKeyPaths } from '@configs/urls'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { api } from '@services/api'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { newAndOldCardNumber } from '@utils/regexes'
import { apiv1 } from '@services/apiv1'
import { useState } from 'react'
import { HttpError } from '@errors/httpError'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { shopResponseErrors } from '@constants/shopResponseErrors'
import { TextInput } from '@components/molecules/forms/inputs/Input'

const getValidationSchema = (t: TFunction) =>
  y.object({
    cardNumber: y
      .string()
      .matches(newAndOldCardNumber, t('forms.validation.cardNumber'))
      .required(t('forms.validation.required')),
    securityCode: y
      .number()
      .typeError(t('forms.validation.number'))
      .integer(t('forms.validation.integer'))
      .required(t('forms.validation.required')),
  })

export const CardNumberSecurityCodeForm: React.FC = () => {
  const navigate = useNavigate()
  const [errorDescription, setErrorDescription] = useState<string>()
  const [errorOccurred, setErrorOccurred] = useState(false)
  const {
    i18n: { language },
    t,
  } = useTranslation()

  const submitErrorHandler = (e: unknown) => {
    const module = '[Code obtainer]'
    const message = 'Something went wrong obtaining share key from card number and pin'
    setErrorOccurred(true)
    console.error(`${module}: ${message}`, e)

    if (e instanceof HttpError && e.json !== null && typeof e.json === 'object') {
      if (Number(e.status) === 503) {
        setErrorDescription('backend.temporarily_unavailable')
        return
      }
      const nonFieldError =
        'non_field_errors' in e.json && Array.isArray(e.json.non_field_errors)
          ? (e.json.non_field_errors[0] as string)
          : undefined
      if (nonFieldError && nonFieldError in shopResponseErrors)
        setErrorDescription('backend.' + nonFieldError)
    }
  }

  const cadeauUrl = import.meta.env.VITE_CADEAU_URL

  const initialValues = { cardNumber: '', securityCode: '' }

  const onCardNumberSecurityCodeSubmit = async ({
    cardNumber,
    securityCode,
  }: typeof initialValues) => {
    try {
      setErrorOccurred(false)
      const { share_key } = await api.shareKeyByCodes(cardNumber, securityCode)

      const cataloguePath = generatePath(shareKeyPaths.confetti, { shareKey: share_key })
      navigate(cataloguePath)
    } catch (e) {
      if (
        e instanceof HttpError &&
        e.json !== null &&
        typeof e.json === 'object' &&
        'non_field_errors' in e.json &&
        Array.isArray(e.json.non_field_errors) &&
        e.json.non_field_errors.length === 1 &&
        e.json.non_field_errors[0] === 'Invalid input.'
      ) {
        try {
          const { share_key: walletShareKey } = await apiv1.checkNumberAndPIN(
            cardNumber,
            securityCode,
          )
          window.location.href = `${cadeauUrl}?c=${walletShareKey}&lng=${language}`
          return
        } catch (e) {
          submitErrorHandler(e)
        }
      }

      submitErrorHandler(e)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onCardNumberSecurityCodeSubmit}
      validationSchema={getValidationSchema(t)}
    >
      {({ isSubmitting }) => {
        return (
          <WithTranslateFormErrors>
            <StyledForm>
              <CardNumberInput
                autoComplete="cardNumber"
                description={t('forms.fields.cardNumber')}
                name="cardNumber"
                placeholder={t('forms.placeholders.cardNumber')}
              />
              <SecurityCodeInput
                autoComplete="securityCode"
                description={t('forms.fields.securityCode')}
                name="securityCode"
                placeholder={t('forms.placeholders.securityCode')}
              />
              {!isSubmitting ? (
                <PrimaryButton iconRight={Icons.Gift} type="submit">
                  {t('forms.actions.openYourGift')}
                </PrimaryButton>
              ) : (
                <LoadingButton />
              )}
              <ErrorWrapper>
                {errorOccurred && (
                  <BasicErrorComponent onClose={() => setErrorOccurred(false)}>
                    {errorDescription ? t(errorDescription) : t('somethingWentWrong')}
                  </BasicErrorComponent>
                )}
              </ErrorWrapper>
            </StyledForm>
          </WithTranslateFormErrors>
        )
      }}
    </Formik>
  )
}

const StyledForm = styled(Form)`
  width: 100%;
`

const ErrorWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px 0 8px 0;
`

const CardNumberInput = styled(TextInput)`
  margin-bottom: 24px;
`

const SecurityCodeInput = styled(TextInput)`
  margin-bottom: 32px;
  width: 50%;
`
