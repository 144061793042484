import { CheckboxInput } from '@components/atoms/forms/inputs/CheckboxInput'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { colours } from '@configs/colours'
import { useField } from 'formik'

import styled from 'styled-components'

type Props = {
  $accent?: string
  checked?: boolean
  disabled?: boolean
  label?: React.ReactNode
  name: string
  value?: string | number | readonly string[] | undefined
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Checkbox: React.FCS<Props> = ({
  $accent = colours.brand.blue,
  className,
  disabled,
  name,
  label,
  ...props
}) => {
  const [field, meta] = useField(name)
  const hasError = !!meta.error

  return (
    <Root className={className}>
      <Label $error={hasError}>
        <Action>
          <CheckboxInput
            $accent={$accent}
            $error={hasError}
            disabled={disabled}
            type="checkbox"
            checked={props.checked ?? field.value}
            {...field}
            {...props}
          />
          {label}
        </Action>
      </Label>
      <Error>{hasError && meta.error}</Error>
    </Root>
  )
}

const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Error = styled.div`
  color: ${colours.error.red};
`

const Root = styled.div`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
  ${BodyRegularCSS};
`

const Label = styled.label<{ disabled?: boolean; $error?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  ${({ $error }) => $error && `color: ${colours.error.red}`};
`
