import { TFunction } from 'i18next'
import * as y from 'yup'

export const getValidationSchema = (t: TFunction) =>
  y.object({
    email: y.string().email().required(t('forms.validation.emailRequired')),
    givenName: y
      .string()
      .min(2, t('forms.validation.atLeastCharacters', { count: 2 }))
      .required(t('forms.validation.nameRequired')),
    lastName: y
      .string()
      .min(2, t('forms.validation.atLeastCharacters', { count: 2 }))
      .required(t('forms.validation.lastNameRequired')),
    phoneNumber: y.string().min(5, t('forms.validation.atLeastCharacters', { count: 5 })),
    phoneAreaCode: y.string().min(1, t('forms.validation.atLeastCharacters', { count: 1 })),
    termsAccepted: y
      .bool()
      .oneOf([true], t('forms.validation.termsMustBeAccepted'))
      .required(t('forms.validation.termsMustBeAccepted')),
  })
