import { Amplify } from 'aws-amplify'
import { ConfigCatProvider } from 'configcat-react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import './main.css'
import './i18n'

import { awsConfig } from '@configs/aws'
import { datadogConfig, datadogRumConfig } from '@configs/datadog'
import { envs } from '@configs/envs'
import { routes } from '@configs/routes'
import { datadogMessages } from '@constants/datadog'
import { CartContextProvider } from '@context/cart'
import { ReceiveFlowContextProvider } from '@context/hashFlows'
import { MainKeyContextProvider } from '@context/mainKey'
import { UserContextProvider } from '@context/user'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const rootElement = document.getElementById('root')

if (!rootElement) throw new Error('Unable to find app root')

Amplify.configure(awsConfig)

datadogLogs.init(datadogConfig)
datadogLogs.logger.info(datadogMessages.shopEntered, { env: import.meta.env.PROD })
datadogRum.init(datadogRumConfig)

ReactDOM.createRoot(rootElement).render(
  <ConfigCatProvider
    options={{ baseUrl: envs.VITE_CONFIGCAT_BASE_URL }}
    sdkKey={envs.VITE_CONFIGCAT_KEY}
  >
    <MainKeyContextProvider>
      <UserContextProvider>
        <ReceiveFlowContextProvider>
          <CartContextProvider>
            <RouterProvider router={createBrowserRouter(routes)} />
          </CartContextProvider>
        </ReceiveFlowContextProvider>
      </UserContextProvider>
    </MainKeyContextProvider>
  </ConfigCatProvider>,
)
