import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FieldArray, Form, Formik } from 'formik'
import { Suspense, useRef, useState } from 'react'

import { CheckboxInput } from '@components/atoms/forms/inputs/CheckboxInput'
import { PanelCard } from '@components/atoms/Panels/PanelCard'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { JoinablePanel } from '@services/api.types'
import { api } from '@services/api'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'

type Props = {
  title?: string
  subtitle?: string
  joinablePanels?: JoinablePanel[]
  userData: {
    firstName?: string
    lastName?: string
    email: string
    phone_area_code?: string
    phone_number?: string
  }
}

export const MorePanels: React.FC<Props> = ({ subtitle, title, joinablePanels, userData }) => {
  const { t } = useTranslation()

  const allSignedUpRef = useRef<HTMLDivElement>(null)

  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false)
  const [hasBeenSignedAlready, setHasBeenSignedAlready] = useState(false)
  const [somePanelsExpired, setSomePanelsExpired] = useState(false)
  const [panelEnrollError, setPanelEnrollError] = useState(false)
  const [panelEnrollLoading, setPanelEnrollLoading] = useState(false)

  if (!joinablePanels) return null

  const panels = joinablePanels.filter(({ status }) => status === 'active')
  const panelIds = panels.map(({ pk }) => pk)
  const initialPanelValues: { panelIds: string[] } = { panelIds }

  return (
    <PanelsWrapper ref={allSignedUpRef}>
      {!successfullySubmitted && (
        <PanelsContent>
          <PanelsHeader>
            <Fonts.MiscelaneousSpecialTitleBold>{title}</Fonts.MiscelaneousSpecialTitleBold>
            <Fonts.BodyLarge>{subtitle}</Fonts.BodyLarge>
          </PanelsHeader>
          <Formik
            onSubmit={async (values) => {
              try {
                setPanelEnrollError(false)
                setPanelEnrollLoading(true)

                const participants = values.panelIds.map((pk) => ({ panel: pk, ...userData }))
                const result = await api.panelSignUp(participants)

                const signedAlready =
                  result?.warnings?.findIndex((e) => e.warning === 'already_signed_up') !== -1
                setHasBeenSignedAlready(signedAlready)

                const panelsFinished =
                  result?.warnings?.findIndex((e) => e.warning === 'panel_expired') !== -1
                setSomePanelsExpired(panelsFinished)

                setSuccessfullySubmitted(true)
                allSignedUpRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
              } catch (e) {
                console.error('[Panels] Something went wrong', e)
              } finally {
                setPanelEnrollLoading(false)
              }
            }}
            initialValues={initialPanelValues}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              return (
                <Form style={{ width: '100%' }}>
                  <FieldArray
                    name="panelIds"
                    render={(_arrayHelpers) =>
                      panels.map((panel) => <PanelCard key={panel.pk} {...panel} />)
                    }
                  />
                  <Label>
                    <CheckboxInput
                      type="checkbox"
                      onChange={() => {
                        if (panelIds.every((panel) => values.panelIds.includes(panel))) {
                          setFieldValue('panelIds', [])
                          return
                        }
                        setFieldValue('panelIds', panelIds)
                      }}
                      checked={panelIds.every((panel) => values.panelIds.includes(panel))}
                    />
                    {t('selectAll')}
                  </Label>
                  {panelEnrollLoading ? (
                    <LoadingButton />
                  ) : (
                    <StyledPrimaryButton type="submit" disabled={!values.panelIds.length}>
                      {t('forms.actions.joinMorePanels')}
                    </StyledPrimaryButton>
                  )}
                  {panelEnrollError && <ErrorWrapper>{t('somethingWentWrong')}</ErrorWrapper>}
                </Form>
              )
            }}
          </Formik>
        </PanelsContent>
      )}
      {successfullySubmitted && (
        <PanelsContent>
          <Celebration>
            <Suspense>
              <VectorsDict.celebration />
            </Suspense>
          </Celebration>
          <Fonts.MiscelaneousSpecialTitleBold>
            {t('referral.allSignedUp')}
          </Fonts.MiscelaneousSpecialTitleBold>
          <GraySubtitle>
            {hasBeenSignedAlready && <span>{t('referral.hasBeenSignedAlready')}</span>}{' '}
            {somePanelsExpired && <span>{t('referral.somePanelsExpired')}</span>}{' '}
            {t('referral.allSignedUpSubtitle')}{' '}
          </GraySubtitle>
        </PanelsContent>
      )}
    </PanelsWrapper>
  )
}

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 8px;
`

const ErrorWrapper = styled.div`
  ${BodyRegularCSS};
  color: ${colours.error.red};
  width: 100%;
  text-align: center;
`

const Celebration = styled.div`
  margin-bottom: 16px;
`

const GraySubtitle = styled.div`
  ${BodyLargeCSS};
  color: ${colours.mist[800]};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PanelsHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
`

const Label = styled.label`
  ${BodyLargeCSS};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  cursor: pointer;
`

const PanelsContent = styled.div`
  box-sizing: border-box;
  padding: 16px;
  width: min(880px, 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PanelsWrapper = styled.div`
  background-color: ${colours.prisma.white};
  width: 100%;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  scroll-margin-top: 80px;
`
