import { useFormikContext } from 'formik'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { FeeInfoStrip } from '@components/atoms/FeeInfoStrip/FeeInfoStrip'
import { Fonts } from '@components/atoms/typography/Fonts'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { SelectField } from '@components/molecules/forms/SelectField/SelectField'
import { colours } from '@configs/colours'
import { claimCodePaths } from '@configs/urls'
import { Languages } from '@constants/language'
import { zIndexes } from '@constants/zIndexes'
import { useCheckoutContext } from '@hooks/useCheckout'
import { useMainKeyContext } from '@hooks/useMainKey'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { DivProps } from '@typeDeclarations/elements/div'
import { calculateCardValueWithFee } from '@utils/calculateCardValueWithFee'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  amount?: number
  calculatedBalance?: number
  cardId: string | number
  currency?: string
  description?: string
  feeInformation?: ReturnType<typeof calculateCardValueWithFee>
  imageUrl?: string
  name?: string
  quantityField: {
    name: string
    idx: number
    values: { id: string | number; text: string | number; value: string | number }[]
  }
  removeHandler?: () => void
}

export const ShoppingCartCard: React.FC<Props & DivProps> = ({
  imageUrl,
  name,
  description,
  removeHandler,
  quantityField,
  currency,
  feeInformation,
  cardId,
  calculatedBalance,
  ...props
}) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language as Languages
  const navigate = useNavigate()
  const { mainKey } = useMainKeyContext()
  const { values } = useFormikContext<CheckoutFormikValues>()
  const { getFeesFromBackend } = useCheckoutContext()

  const balance = getLocalisedPrice(
    language,
    calculatedBalance ?? feeInformation?.cardBalance,
    currency,
  )

  return (
    <Root {...props}>
      <Layout>
        <ImgWrapper
          $backgroundImage={imageUrl}
          onClick={() =>
            navigate(
              generatePath(claimCodePaths.product, { productId: cardId, claimCode: mainKey }),
            )
          }
        >
          <StyledInfoStrip
            currency={currency}
            feeFlat={feeInformation?.feeFlat}
            feePercentage={feeInformation?.feePercentage}
            isFeeDeducted={feeInformation?.isFeeDeducted}
            isFeePaid={feeInformation?.isFeePaid}
          />
        </ImgWrapper>
        <VerticalArrangement>
          <div>
            <Fonts.TitleCardTitles>{name}</Fonts.TitleCardTitles>
            <CardDescription>
              <Markdown className="__markdown">{description}</Markdown>
            </CardDescription>
          </div>
          <VisibleFromDesktop>
            <Actions>
              <SelectField
                name={quantityField.name}
                onValueChange={async (newValue) => {
                  const updated = produce(values, (draft) => {
                    draft.cards[quantityField.idx].quantity = Number(newValue)
                  })

                  void getFeesFromBackend(updated)
                }}
                options={quantityField.values.map((v) => ({
                  value: v.value,
                  label: v.text.toString(),
                }))}
              />
              <Quantity>{balance}</Quantity>
              <StyledSecondaryButton
                iconLeft={Icons.Bin}
                onClick={() => removeHandler?.()}
                type="button"
              >
                {t('forms.actions.remove')}
              </StyledSecondaryButton>
            </Actions>
          </VisibleFromDesktop>
        </VerticalArrangement>
        <VisibleToDesktop>
          <Actions>
            <SelectField
              name={quantityField.name}
              onValueChange={(newValue) => {
                const updated = produce(values, (draft) => {
                  draft.cards[quantityField.idx].quantity = Number(newValue)
                })

                void getFeesFromBackend(updated)
              }}
              options={quantityField.values.map((v) => ({
                value: v.value,
                label: v.text.toString(),
              }))}
            />
            <Quantity>{balance}</Quantity>
            <StyledSecondaryButton
              iconLeft={Icons.Bin}
              onClick={() => removeHandler?.()}
              type="button"
            />
          </Actions>
        </VisibleToDesktop>
      </Layout>
    </Root>
  )
}

const VisibleFromDesktop = styled.div`
  display: none;
  ${mediaQueries.from.breakpoint.desktop} {
    display: block;
  }
`

const VisibleToDesktop = styled.div`
  display: none;
  grid-column: 1/3;
  ${mediaQueries.to.breakpoint.desktop} {
    display: block;
  }
`

const Actions = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr min-content;
  align-items: center;
  gap: 8px;
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 12px;
`

const Root = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 12px;
`

const ImgWrapper = styled.div<{ $backgroundImage?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${(p) => p.$backgroundImage && `background-image: url("${p.$backgroundImage}")`};
  background-size: cover;
  border-radius: 12px;
  width: 192px;
  ${mediaQueries.to.breakpoint.desktop} {
    width: 116px;
  }
`

const StyledInfoStrip = styled(FeeInfoStrip)`
  z-index: ${zIndexes.baseOnTop + 1};
  width: 192px;
  ${mediaQueries.to.breakpoint.desktop} {
    width: 116px;
  }
`

const CardDescription = styled(Fonts.BodyXSmall)`
  color: ${colours.mist[700]};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 0 4px 0;
`

const Quantity = styled(Fonts.ButtonCTALabelSmall)`
  box-sizing: border-box;
  border-radius: 44px;
  background-color: ${colours.mist[100]};
  color: ${colours.mist[800]};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
`

const VerticalArrangement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledSecondaryButton = styled(SecondaryButton)`
  height: 40px;
`
