import { envs } from '@configs/envs'

export const getReferralLink = (referralCode: Maybe<string>): string | undefined => {
  if (!referralCode) return
  if (import.meta.env.MODE === 'development')
    return `http://localhost:5173/refer/enroll/${referralCode}`
  if (envs.VITE_DOTENV === 'production') return `http://shop.yesty.nl/refer/enroll/${referralCode}`

  return `http://shop.yesty-test.nl/refer/enroll/${referralCode}`
}

export const getReferralMessage = (referralCode: Maybe<string>, textDescription: Maybe<string>) => {
  if (!referralCode) return

  let referral = getReferralLink(referralCode)

  if (typeof textDescription === 'string' && !!textDescription)
    referral = `${referral} - ${textDescription}`

  return referral
}
