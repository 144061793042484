import { PropsWithChildren } from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { mediaQueries } from '@utils/mediaQueries'
import { colours } from '@configs/colours'
import { ButtonTextLinkCSS } from '../typography/css'
import { Checkbox } from '@components/molecules/forms/inputs/Checkbox'
import { useFormikContext } from 'formik'
import { JoinablePanel } from '@services/api.types'
import { useTranslation } from 'react-i18next'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'

type Props = JoinablePanel

export const PanelCard: React.FC<PropsWithChildren<Props>> = ({ pk, content, panel_image }) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<{ panelIds: string[] }>()

  return (
    <Root>
      <StyledCheckbox key={pk} name="panelIds" value={pk} checked={values.panelIds.includes(pk)} />
      {panel_image && (
        <PanelLeft>
          <Img src={panel_image} />
        </PanelLeft>
      )}
      <PanelRight>
        <TextWrapper>
          <BodyFont>
            <Markdown className="__markdown">{content?.markdown_description}</Markdown>
          </BodyFont>
          {content?.terms_and_conditions && (
            <Url target="_blank" href={content?.terms_and_conditions}>
              {t('termsAndConditions')}
            </Url>
          )}
        </TextWrapper>
      </PanelRight>
    </Root>
  )
}

const Url = styled.a`
  ${BodyLargeCSS};
  text-decoration: underline;
`

const BodyFont = styled(Fonts.BodyRegular)`
  color: ${colours.mist[800]};
  ul {
    padding: 0 0 0 24px;
  }
  a {
    ${ButtonTextLinkCSS};
    text-decoration: underline;
    color: ${colours.prisma.black};
  }
`

const Img = styled.img`
  align-self: center;
  height: 144px;
  width: 216px;
  border-radius: 4px;
`

const Root = styled.div`
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(6px);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  padding: 12px;
  gap: 16px;
  position: relative;
  width: 100%;
  ${mediaQueries.to.breakpoint.mobileL} {
    flex-direction: column;
  }
`

const PanelLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PanelRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${mediaQueries.to.breakpoint.mobileL} {
    flex-direction: column;
  }
`

const StyledCheckbox = styled(Checkbox)`
  width: min-content;
  height: min-content;
  position: absolute;
  top: 8px;
  right: 8px;
`

const TextWrapper = styled.div`
  flex-grow: 3;
  box-sizing: border-box;
  max-width: 90%;
`
