import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { HttpError } from '@errors/httpError'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useUserContext } from '@hooks/useUserContext'
import { api } from '@services/api'
import { Catalogue } from '@services/api.types'

export type ProductsContext = {
  products: Catalogue | null | undefined
  productsErrorCode: number | undefined
  productsLoading: boolean
  reloadProducts: () => void | Promise<void>
}

const defaultCtx = {
  products: null,
  productsErrorCode: undefined,
  productsLoading: false,
  reloadProducts: async () => {},
}

export const ProductsContext = createContext<ProductsContext>(defaultCtx)

export const ProductsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { mainKey } = useMainKeyContext()
  const { isLoggedIn } = useUserContext()
  const { i18n } = useTranslation()
  const [products, setProducts] = useState<Catalogue | undefined | null>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<number | undefined>(undefined)
  const language = i18n.language
  const { claimCode } = useParams()

  const reloadProducts = useCallback(async () => {
    setError(undefined)
    setLoading(true)

    if (!claimCode || claimCode !== mainKey) return

    try {
      const apiProducts = await api.catalogue(claimCode, language)
      setProducts(apiProducts)
    } catch (e) {
      if (e instanceof TypeError) {
        setError(() => {
          throw e
        })
      } else if (e instanceof HttpError) {
        setError(e.status)
      } else {
        throw e
      }

      console.warn(
        '[Products] Something went wrong reloading catalogue. Products list may be stale',
        e,
      )
    } finally {
      setLoading(false)
    }
  }, [claimCode, language, mainKey])

  useEffect(() => {
    setError(undefined)
    setLoading(true)

    if (!claimCode || claimCode !== mainKey) return

    let ignore = false

    api
      .catalogue(claimCode, language)
      .then((apiProducts) => {
        if (!ignore) setProducts(apiProducts)
      })
      .catch((e: unknown) => {
        if (e instanceof TypeError) {
          setError(() => {
            throw e
          })
        } else if (e instanceof HttpError) {
          setError(e.status)
        } else {
          throw e
        }
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      ignore = true
    }
  }, [language, claimCode, mainKey, isLoggedIn])

  return (
    <ProductsContext.Provider
      value={{ products, productsErrorCode: error, productsLoading: loading, reloadProducts }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
