import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Dispatch, Fragment, SetStateAction, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { mediaQueries } from '@utils/mediaQueries'
import { Form, Formik } from 'formik'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { TextInput } from '../forms/inputs/Input'
import { getValidationSchema } from '@schemas/pages/refer/schema'
import { api } from '@services/api'
import { useMainKeyContext } from '@hooks/useMainKey'
import { getReferralLink, getReferralMessage } from '@utils/getReferrals'
import { useSettingsContext } from '@hooks/useSettings'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useUserContext } from '@hooks/useUserContext'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'

type Props = {
  generatedReferralCode: Maybe<string>
  setGeneratedReferralCode: Dispatch<SetStateAction<string | undefined>>
  referralCode: Maybe<string>
  onClose?: () => void
  textDescription: Maybe<string>
}

export const ShareModalContent: React.FC<Props> = ({
  generatedReferralCode,
  onClose,
  referralCode,
  setGeneratedReferralCode,
  textDescription,
}) => {
  const { t } = useTranslation()
  const { email: userEmail } = useUserContext()
  const { hashFlows } = useHashFlowContext()
  const { mainKey } = useMainKeyContext()
  const { setReferralCode, setPanel, setPersonalization } = useSettingsContext()

  const prefillEmail = mainKey ? hashFlows[mainKey]?.prefillEmail : undefined
  const initialFormEmail = userEmail || prefillEmail || ''

  const initialValues = { email: initialFormEmail }

  const code = referralCode || generatedReferralCode
  const referralLink = getReferralLink(code)
  const refferalMessage = getReferralMessage(code, textDescription)

  return (
    <Root>
      <Title>{t('share')}</Title>
      {!referralLink && (
        <Formik
          initialValues={initialValues}
          onSubmit={async ({ email }: typeof initialValues, { setStatus }) => {
            setStatus('')

            try {
              if (mainKey) {
                const response = await api.createReferralCode(mainKey, email)

                setGeneratedReferralCode(response.referral_code)
                setReferralCode(response.referral_code)
                setPanel(response.panel)
                setPersonalization(response.panel_personalization)
              }
            } catch (e) {
              console.error('[Referral] Generating referral code failed', e)
              setStatus(t('somethingWentWrong'))
            }
          }}
          validationSchema={getValidationSchema(t)}
        >
          {({ isSubmitting, status, setStatus }) => (
            <WithTranslateFormErrors>
              <Form onClick={() => setStatus('')}>
                <ProvideYourEmailExplanation>
                  {t('referral.provideEmailForReferralLinkExplanation')}
                </ProvideYourEmailExplanation>
                <StyledTextInput
                  autoComplete="email"
                  description={t('forms.fields.email')}
                  name="email"
                  type="text"
                  placeholder={t('forms.placeholders.email')}
                />
                {isSubmitting ? (
                  <StyledLoadingButton />
                ) : (
                  <GeneratePrimaryButton type="submit">
                    {t('generateShareLink')}
                  </GeneratePrimaryButton>
                )}
                {status && <Error>{t(status)}</Error>}
              </Form>
            </WithTranslateFormErrors>
          )}
        </Formik>
      )}
      <Fragment>
        <ShareIcons $hasLink={!!refferalMessage}>
          <Mail href={`mailto:?body=${refferalMessage}`}>
            <Suspense>
              <IconComponents.letter stroke={colours.prisma.white} />
            </Suspense>
          </Mail>
          <Whatsapp href={`https://wa.me/?text=${refferalMessage}`}>
            <Suspense>
              <IconComponents.whatsapp stroke={colours.prisma.white} />
            </Suspense>
          </Whatsapp>
          <Twitter href={`https://twitter.com/messages/compose?&text=${refferalMessage}`}>
            <Suspense>
              <IconComponents.twitter />
            </Suspense>
          </Twitter>
          <Facebook>
            <Suspense>
              <img src="/images/Facebook_Logo_Primary.png" />
            </Suspense>
          </Facebook>
        </ShareIcons>
        <CopyArea>
          <LinkToCopy onClick={() => navigator.clipboard.writeText(referralLink ?? '')}>
            {referralLink}
          </LinkToCopy>
          <StyledPrimaryButton
            disabled={!referralLink}
            onClick={() => navigator.clipboard.writeText(referralLink ?? '')}
            iconRight={Icons.Link}
          >
            {t('copy')}
          </StyledPrimaryButton>
        </CopyArea>
      </Fragment>
      <SecondaryButton onClick={() => onClose?.()}>{t('cancel')}</SecondaryButton>
    </Root>
  )
}

const StyledLoadingButton = styled(LoadingButton)`
  margin-bottom: 16px;
`

const ProvideYourEmailExplanation = styled(Fonts.BodyRegular)`
  margin-bottom: 16px;
  color: ${colours.mist[800]};
`

const Error = styled(Fonts.BodyRegular)`
  color: ${colours.error.red};
  margin-bottom: 16px;
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: 16px;
`

const GeneratePrimaryButton = styled(PrimaryButton)`
  margin-bottom: 16px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: fit-content;
  min-height: 56px;
`

const LinkToCopy = styled.div`
  ${BodyRegularCSS};
  flex-grow: 2;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  word-break: break-all;
`

const CopyArea = styled.div`
  align-items: center;
  background-color: ${colours.mist[200]};
  border-radius: 8px;
  display: flex;
  gap: 16px;
  min-height: 56px;
  margin-bottom: 24px;
  padding: 8px 8px 8px 16px;
  ${mediaQueries.to.breakpoint.tablet} {
    flex-direction: column;
  }
`

const Root = styled.div`
  padding: 32px 24px 24px 24px;
  ${mediaQueries.from.breakpoint.tablet} {
    width: 600px;
  }
`

const Title = styled(Fonts.TitleHeaderH2)``

const ShareIcons = styled.div<{ $hasLink?: boolean }>`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
  justify-content: center;
  ${(p) => !p.$hasLink && `filter: grayscale()`};
  ${(p) => !p.$hasLink && `pointer-events: none`}
`

const Mail = styled.a`
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Whatsapp = styled.a`
  --whatsapp-color: #25d366;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--whatsapp-color);
`

const Twitter = styled.a`
  --twitter-color: ${colours.prisma.black};
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--twitter-color);
`

const Facebook = styled.div`
  --facebook-color: rgb(44, 99, 251);
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--facebook-color);
  display: none;
`
