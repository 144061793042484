import { FetchApiOptions } from './fetchApi.types'
import { fetchApiOrThrow } from './fetchApiOrThrow'

export const fetchApiDataOrThrow = async <T>(
  path: string,
  requestOptions?: RequestInit,
  options?: FetchApiOptions,
): Promise<T> => {
  const response = await fetchApiOrThrow(path, requestOptions, options)
  if (response.status === 204) throw new Error('204 No content')

  return response.json() as T
}
