import { HttpError } from '@errors/httpError'

import { fetchApi } from './fetchApi'
import { FetchApiOptions } from './fetchApi.types'

export const fetchApiOrThrow = async (
  path: string,
  requestOptions?: RequestInit,
  options?: FetchApiOptions,
): Promise<Response> => {
  const res = await fetchApi(path, requestOptions, options)
  const status = res.status

  if (!res.ok) {
    const raw = await res.text()
    const httpError = new HttpError(raw, status, raw, 'Y')

    throw httpError
  }

  return res
}
