import fetchWrapper from 'fetch-retry'

import { apiBaseURL } from '@configs/urls'
import { FetchApiOptions } from './fetchApi.types'

export const fetchApi = async (
  path: string,
  requestOptions?: RequestInit,
  options?: FetchApiOptions,
): Promise<Response> => {
  const fetchWithRetry = fetchWrapper(window.fetch)
  const base = options?.baseUrl ?? apiBaseURL

  const config = {
    method: 'GET',
    ...requestOptions,
    headers: { ...requestOptions?.headers },
  }

  return fetchWithRetry(`${base}${path}`, config)
}
