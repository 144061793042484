import { PropsWithChildren, Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { colours } from '@configs/colours'
import { pieFill } from '@styles/animations/pieFill'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'

type TooltipTypes = 'success' | 'error' | 'shop' | 'info' | 'tipIdea' | 'update' | 'generic'

const typePropertiesMap: Record<
  TooltipTypes,
  {
    bg: string
    iconBg: string
    txt: string
    icon?: Icons
    title: string
    txtLinkColor: string
    toastActionBg: string
    toastButtonBg: string
    toastButtonChevron: string
  }
> = {
  success: {
    bg: colours.plant[110],
    icon: Icons.Checkmark,
    iconBg: colours.plant[100],
    title: colours.prisma.white,
    txt: colours.plant[10],
    txtLinkColor: colours.prisma.white,
    toastActionBg: colours.plant[100],
    toastButtonBg: colours.prisma.black,
    toastButtonChevron: colours.prisma.white,
  },
  error: {
    bg: colours.tomato[110],
    icon: Icons.ExclamationMark,
    iconBg: colours.tomato[100],
    title: colours.prisma.white,
    txt: colours.tomato[20],
    txtLinkColor: colours.prisma.white,
    toastActionBg: colours.tomato[120],
    toastButtonBg: colours.prisma.black,
    toastButtonChevron: colours.prisma.white,
  },
  shop: {
    bg: colours.prisma.white,
    icon: Icons.Shop,
    iconBg: colours.mist[100],
    title: colours.brand.dark,
    txt: colours.mist[800],
    txtLinkColor: colours.brand.blue,
    toastActionBg: colours.blues[10],
    toastButtonBg: colours.brand.blue,
    toastButtonChevron: colours.brand.blue,
  },
  info: {
    bg: colours.plum[110],
    icon: Icons.Info,
    iconBg: colours.plum[80],
    title: colours.prisma.white,
    txt: colours.plum[20],
    txtLinkColor: colours.prisma.white,
    toastActionBg: colours.plum[115],
    toastButtonBg: colours.prisma.black,
    toastButtonChevron: colours.prisma.white,
  },
  update: {
    bg: colours.blues[110],
    icon: Icons.Megaphone,
    iconBg: colours.blues[80],
    title: colours.prisma.white,
    txt: colours.blues[20],
    txtLinkColor: colours.prisma.white,
    toastActionBg: colours.brand.blue,
    toastButtonBg: colours.prisma.black,
    toastButtonChevron: colours.prisma.white,
  },
  generic: {
    bg: colours.mist[900],
    icon: Icons.BigSmile,
    iconBg: colours.brand.dark,
    title: colours.prisma.white,
    txt: colours.mist[400],
    txtLinkColor: colours.prisma.white,
    toastActionBg: colours.mist[800],
    toastButtonBg: colours.prisma.black,
    toastButtonChevron: colours.prisma.white,
  },
  tipIdea: {
    bg: colours.sunflower[100],
    icon: Icons.Bulb,
    iconBg: colours.prisma.white,
    title: colours.brand.dark,
    txt: colours.mist[800],
    txtLinkColor: colours.prisma.black,
    toastActionBg: colours.sunflower[110],
    toastButtonBg: colours.prisma.black,
    toastButtonChevron: colours.prisma.white,
  },
}

type Props = {
  ctaText?: React.ReactNode
  icon?: Icons
  onClose?: () => void
  onCtaAction?: () => void
  onToastAction?: () => void
  toastActionIcon?: Icons
  textLink?: React.ReactNode
  title?: React.ReactNode
  type?: TooltipTypes
  timeout?: number
}

export const Banner: React.FCS<PropsWithChildren<Props>> = ({
  children,
  className,
  ctaText,
  icon,
  onClose,
  onCtaAction,
  onToastAction,
  textLink,
  timeout,
  title,
  toastActionIcon,
  type = 'update',
}) => {
  const bg = typePropertiesMap[type].bg
  const iconBg = typePropertiesMap[type].iconBg
  const txt = typePropertiesMap[type].txt
  const titleColor = typePropertiesMap[type].title
  const toastActionBg = typePropertiesMap[type].toastActionBg
  const toastButtonBg = typePropertiesMap[type].toastButtonBg
  const toastButtonChevron = typePropertiesMap[type].toastButtonChevron

  const I = icon ? IconComponents[icon] : IconComponents[typePropertiesMap[type].icon ?? Icons.Info]
  const ToastIcon = toastActionIcon ? IconComponents[toastActionIcon] : IconComponents[Icons.Info]

  return (
    <Root $background={bg} className={className}>
      <Info>
        <Circle $background={iconBg} $timeout={timeout}>
          <Suspense>
            <I fill={txt} stroke={txt} />
          </Suspense>
        </Circle>
        <Content>
          <Title $color={titleColor}>{title}</Title>
          <Text $color={txt}>{children}</Text>
          {textLink && (
            <TextLinkWrapper $txtLinkColor={typePropertiesMap[type].txtLinkColor}>
              {textLink}
            </TextLinkWrapper>
          )}
        </Content>
      </Info>
      {(!!onCtaAction || !!onToastAction || !!onClose) && (
        <ActionsWrapper>
          {onCtaAction && type !== 'generic' && (
            <PrimaryButton iconRight={Icons.ArrowRight} onClick={() => onCtaAction()}>
              {ctaText}
            </PrimaryButton>
          )}
          {onCtaAction && type === 'generic' && (
            <SecondaryButton iconRight={Icons.ArrowRight} onClick={() => onCtaAction()}>
              {ctaText}
            </SecondaryButton>
          )}
          {onToastAction && (
            <ToastActionWrapper $bg={toastActionBg} onClick={() => onToastAction()}>
              <ToastActionButton $bg={toastButtonBg}>
                <Suspense>
                  <ToastIcon fill="white" stroke="white" />
                </Suspense>
              </ToastActionButton>
              <Suspense>
                <IconComponents.chevron
                  fill={toastButtonChevron}
                  stroke={toastButtonChevron}
                  style={{ transform: 'rotate(90deg)' }}
                />
              </Suspense>
            </ToastActionWrapper>
          )}
          {onClose && (
            <CloseWrapper onClick={() => onClose()}>
              <Suspense>
                <IconComponents.closeOutline fill={txt} stroke={txt} />
              </Suspense>
            </CloseWrapper>
          )}
        </ActionsWrapper>
      )}
    </Root>
  )
}

const Info = styled.div`
  display: flex;
  align-items: center;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
`

const CloseWrapper = styled.div`
  cursor: pointer;
  grid-column: 3/4;
  grid-row: 1/2;
`

const TextLinkWrapper = styled(Fonts.BodyRegular)<{ $txtLinkColor: string }>`
  font-weight: 500;
  text-decoration: underline;
  color: ${(p) => p.$txtLinkColor};
`

const Root = styled.div<{ $background: string }>`
  align-items: center;
  border-radius: 12px;
  background-color: ${({ $background }) => $background};
  justify-content: space-between;
  padding: 16px 20px;
  grid-template-rows: 1fr;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  ${mediaQueries.from.breakpoint.tablet} {
    grid-template-columns: 1fr min-content;
  }
`

const Circle = styled.div<{ $background: string; $timeout?: number }>`
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  background-color: ${({ $background }) => $background};
  border-radius: 100%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/2;
  grid-row: 1/2;
  animation: ${pieFill};
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-duration: ${(p) => (typeof p.$timeout === 'number' ? `${p.$timeout}ms` : 0)};
  animation-fill-mode: forwards;
  background: ${(p) =>
    `conic-gradient(${p.$background} 0deg, ${p.$background} var(--angle), rgba(0,0,0,0) var(--angle), rgba(0,0,0,0) 360deg)`};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: 2/3;
  grid-row: 1/2;
`

const Title = styled(Fonts.BodyRegular)<{ $color: string }>`
  color: ${(p) => p.$color};
  font-weight: bold;
`

const Text = styled(Fonts.BodyRegular)<{ $color: string }>`
  color: ${(p) => p.$color};
`

const ToastActionButton = styled.div<{ $bg: string }>`
  width: 36px;
  height: 36px;
  background-color: ${(p) => p.$bg};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`

const ToastActionWrapper = styled.div<{ $bg: string }>`
  border-radius: 12px;
  background-color: ${(p) => p.$bg};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
`
