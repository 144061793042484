import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { Accordion } from '@components/atoms/Accordion/Accordion'
import { Chip } from '@components/atoms/Chip/Chip'
import { WidthBoundary, WidthBoundaryCSS } from '@components/atoms/Content/WidthBoundary'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { ButtonTextLinkCSS } from '@components/atoms/typography/css'
import { ButtonCTALabelSmallCSS } from '@components/atoms/typography/css/ButtonCTALabelSmallCSS'
import { Fonts } from '@components/atoms/typography/Fonts'
import { CardStack } from '@components/molecules/CardStack/CardStack'
import { SkipClaimForm } from '@components/organisms/forms/SkipClaimForm/SkipClaimForm'
import { colours } from '@configs/colours'
import { shareKeyPaths } from '@configs/urls'
import { Languages } from '@constants/language'
import { useData } from '@hooks/useData'
import { api } from '@services/api'
import { ReceiveInfo } from '@services/api.types'
import { afterDelay } from '@utils/afterDelay'
import { formattedDate, insertSpaces } from '@utils/formatter'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'

const COOLDOWN_TIME = 4000
type DetailEntryProps = {
  title: string
  text: string
  expanded: boolean
  setExpanded: (value: boolean) => void
}
const DetailEntry: React.FC<DetailEntryProps> = ({ title, text, expanded, setExpanded }) => {
  return (
    <div>
      <DetailEntryMobile>
        <StyledAccordion
          expanded={expanded}
          onExpandedChange={() => {
            setExpanded(!expanded)
          }}
          titleText={<Fonts.TitleHeaderH2>{title}</Fonts.TitleHeaderH2>}
        >
          <AboutText>
            <Markdown className="__markdown">{text}</Markdown>
          </AboutText>
        </StyledAccordion>
      </DetailEntryMobile>
      <DetailEntryDesktop>
        <AboutTitle>{title}</AboutTitle>
        <AboutText>
          <Markdown className="__markdown">{text}</Markdown>
        </AboutText>
      </DetailEntryDesktop>
    </div>
  )
}
const DetailEntryMobile = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`
const DetailEntryDesktop = styled.div`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`
const Details: React.FC = () => {
  const { i18n, t } = useTranslation()
  const { shareKey = '' } = useParams()

  const navigate = useNavigate()
  const language = i18n.language as Languages
  const [about, setAbout] = useState(true)
  const [validity, setValidity] = useState(false)
  const [howItWorks, setHowItWorks] = useState(false)
  const [value, setValue] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const { data } = useData<ReceiveInfo>(
    useCallback(() => api.receiveInfo(shareKey, i18n.language), [i18n.language, shareKey]),
  )

  useEffect(() => {
    if (data && !data.skip_claim_step) {
      navigate(generatePath(shareKeyPaths.claim, { shareKey }))
    }
  }, [data, navigate, shareKey])
  const [cardNumbercopied, setCardNumberCopied] = useState(false)
  const [claimCodeCopied, setClaimCodeCopied] = useState(false)

  const copyHandler = async (
    code?: string | null,
    stateSeter?: Dispatch<SetStateAction<boolean>>,
  ) => {
    if (!code || !stateSeter) return
    void navigator.clipboard.writeText(code)
    stateSeter(true)
    await afterDelay(() => stateSeter(false), COOLDOWN_TIME / 2)
  }

  return (
    <Root>
      <StyledWidthBoundary>
        <Content>
          <Grid>
            <DetailsPart>
              <ProductName>{data?.product?.name}</ProductName>
              {data?.product?.description_short && (
                <DetailEntry
                  expanded={about}
                  setExpanded={setAbout}
                  text={data.product.description_short}
                  title={t('about')}
                />
              )}
              {data?.product?.spending_text && (
                <DetailEntry
                  expanded={howItWorks}
                  setExpanded={setHowItWorks}
                  text={data.product.spending_text}
                  title={t('howItWorks')}
                />
              )}
              {data?.product?.expiration_text && (
                <DetailEntry
                  expanded={validity}
                  setExpanded={setValidity}
                  text={data.product.expiration_text}
                  title={t('validity')}
                />
              )}
              {data?.balance && (
                <DetailEntry
                  expanded={value}
                  setExpanded={setValue}
                  text={getLocalisedPrice(language, data.balance, data.product?.currency)}
                  title={t('value')}
                />
              )}
            </DetailsPart>
            <CardFrame>
              <CardStackWrapper>
                <CardStack
                  currency={data?.product?.currency}
                  debitValue={data?.balance ? `${data.balance}` : undefined}
                  slotImage={data?.product?.primary_image}
                />
              </CardStackWrapper>
              <MobileCardImageWrapper>
                <Img src={data?.product?.primary_image ?? ''} />
                <StyledChip>
                  {getLocalisedPrice(i18n.language, Number(data?.balance), data?.product?.currency)}
                </StyledChip>
              </MobileCardImageWrapper>
              <GrayBackground>
                {data?.claim_code && (
                  <ClaimCode>
                    <div>
                      <Title>{t('claimCode')}</Title>
                      <CardNumberWrapper>
                        <Codes>{data.claim_code}</Codes>
                        <CopyButton
                          onClick={() => copyHandler(data.claim_code, setClaimCodeCopied)}
                        >
                          <CopyText>{t('copy')}</CopyText>
                          <Suspense>
                            {claimCodeCopied ? (
                              <CheckMarkIcon
                                fill={colours.prisma.white}
                                stroke={colours.prisma.white}
                              />
                            ) : (
                              <CopyIcon fill={colours.prisma.white} stroke={colours.prisma.white} />
                            )}
                          </Suspense>
                        </CopyButton>
                      </CardNumberWrapper>
                      {data?.expiry_date && (
                        <div>
                          <Title>{t('expiryDate')}</Title>
                          <Codes>{formattedDate(data.expiry_date)}</Codes>
                        </div>
                      )}
                    </div>
                  </ClaimCode>
                )}
                {!data?.claim_code && (
                  <NumberAndPin>
                    {data?.card_number && (
                      <div>
                        <Title>{t('cardNumber')}</Title>
                        <CardNumberWrapper>
                          <Codes>{insertSpaces(data.card_number, 4)}</Codes>
                          <CopyButton
                            onClick={() => copyHandler(data.card_number, setCardNumberCopied)}
                          >
                            <CopyText>{t('copy')}</CopyText>
                            <Suspense>
                              {cardNumbercopied ? (
                                <CheckMarkIcon
                                  fill={colours.prisma.white}
                                  stroke={colours.prisma.white}
                                />
                              ) : (
                                <CopyIcon
                                  fill={colours.prisma.white}
                                  stroke={colours.prisma.white}
                                />
                              )}
                            </Suspense>
                          </CopyButton>
                        </CardNumberWrapper>
                      </div>
                    )}
                    {data?.security_code && (
                      <div>
                        <Title>{t('securityCode')}</Title>
                        <Codes>{insertSpaces(data.security_code, 3)}</Codes>
                      </div>
                    )}
                    {data?.expiry_date && (
                      <div>
                        <Title>{t('expiryDate')}</Title>
                        <Codes>{formattedDate(data.expiry_date)}</Codes>
                      </div>
                    )}
                  </NumberAndPin>
                )}
              </GrayBackground>
              <StyledClaimForm data={data} shareKey={shareKey} />
              <ShowMessageWrapper onClick={() => setShowMessage(!showMessage)}>
                <Suspense>
                  {showMessage ? (
                    <IconComponents.eyeClosed
                      fill={colours.brand.blue}
                      stroke={colours.brand.blue}
                    />
                  ) : (
                    <IconComponents.eyeOpen fill={colours.brand.blue} stroke={colours.brand.blue} />
                  )}
                </Suspense>
                <BlueLink>{showMessage ? t('hideMessage') : t('showMessage')}</BlueLink>
              </ShowMessageWrapper>
              <MessageWrapper>
                {data?.message && showMessage && (
                  <DesktopGreetingBox>{`”${data.message}”`}</DesktopGreetingBox>
                )}
              </MessageWrapper>
            </CardFrame>
          </Grid>
        </Content>
      </StyledWidthBoundary>
      <AboutFooter />
    </Root>
  )
}

const ClaimCode = styled.div``
const NumberAndPin = styled.div``

const MessageWrapper = styled.div`
  width: 100%;
`

const DesktopGreetingBox = styled(Fonts.IntroSubtextSmall)`
  padding-top: 64px;
  padding-bottom: 64px;
  margin-bottom: 40px;
  text-align: center;
  border-radius: 16px;
  background-color: ${colours.prisma.background};
  overflow-wrap: break-word;
  ${WidthBoundaryCSS}
`

const Grid = styled.div`
  display: grid;
  gap: 0px;
  justify-items: center;
  align-items: start;
  margin-bottom: 64px;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;
  }
`

const CardFrame = styled.div`
  width: min(560px, 100vw);
  min-width: min(560px, 100vw);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 28px 24px 24px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 24px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  justify-content: flex-start;
  ${mediaQueries.to.breakpoint.desktop} {
    order: -1;
    width: 100%;
    min-width: 100%;
  }
  ${mediaQueries.to.breakpoint.tablet} {
    border-radius: 0;
  }
`

const CardStackWrapper = styled.div`
  background-color: ${colours.brand.dark};
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`

const ShowMessageWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  cursor: pointer;
`

const GrayBackground = styled.div`
  background-color: ${colours.mist[100]};
  width: 100%;
  border-radius: 16px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${mediaQueries.to.breakpoint.mobileL} {
    background-color: white;
    padding: 0;
  }
`

const CardNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`

const Codes = styled(Fonts.ButtonCTALabelLarge)`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  background-color: white;
  color: ${colours.mist[700]};
  padding: 4px 12px 4px 12px;
  gap: 8px;
  border-radius: 8px;
  width: fit-content;
  letter-spacing: 0.3em;
  white-space: break-spaces;
  word-break: break-all;
  ${mediaQueries.to.breakpoint.mobileL} {
    color: ${colours.mist[900]};
    background-color: ${colours.mist[200]};
  }
`

const CopyIcon = styled(IconComponents.copy)``

const CheckMarkIcon = styled(IconComponents.checkmark)``

const Img = styled.img`
  width: 100%;
  border-radius: 16px;
  align-self: center;
`

const StyledChip = styled(Chip)`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0 12px;
  border-radius: 16px;
`

const DetailsPart = styled.div`
  padding: 88px 0px 88px 0px;
  max-width: 576px;
  order: -1;
  width: 100%;
  ${mediaQueries.to.breakpoint.desktop} {
    margin: 0 16px 0 16px;
    padding: 0;
    max-width: 95vw;
  }
`

const CopyButton = styled.div`
  background-color: ${colours.brand.dark};
  color: ${colours.prisma.white};
  border-radius: 12px;
  padding: 8px 24px;
  ${ButtonCTALabelSmallCSS}
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
`
const StyledAccordion = styled(Accordion)`
  margin-bottom: 24px;

  color: ${colours.brand.dark};
`
const MobileCardImageWrapper = styled.div`
  position: relative;
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
`
const CopyText = styled.div``

const StyledClaimForm = styled(SkipClaimForm)`
  width: 100%;
  margin-bottom: 24px;
`

const StyledWidthBoundary = styled(WidthBoundary)`
  flex-grow: 10;
  ${mediaQueries.to.breakpoint.mobileL} {
    padding-top: 32px;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    padding: 0;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f4f6f7 50%, #edf0f1 100%);
`
const ProductName = styled(Fonts.TitleHeaderH1S)`
  color: ${colours.brand.dark};
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 72px;
  line-height: 80px;
  ${mediaQueries.to.breakpoint.desktop} {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 52px;
  }
`
const Title = styled(Fonts.TitleHeaderH1S)`
  color: ${colours.brand.dark};
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  ${mediaQueries.to.breakpoint.desktop} {
    margin-bottom: 24px;
  }
`

const Content = styled.div`
  flex-grow: 10;
  width: 100%;
  margin-top: 48px;
  gap: 64px;
  ${mediaQueries.from.breakpoint.desktop} {
    display: flex;
    gap: 64px;
  }
`

const AboutTitle = styled(Fonts.TitleHeaderH2)`
  color: ${colours.brand.dark};
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`

const AboutText = styled(Fonts.BodyRegular)`
  color: ${colours.mist[900]};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin: 0px;
  div > p {
    margin: 0px 0px 24px 0px;
  }
`

const BlueLink = styled.div`
  margin-left: 8px;
  justify-content: center;
  color: ${colours.brand.blue};
  text-decoration: underline;
  font-size: 16px;
  ${ButtonTextLinkCSS}
  overflow-wrap: break-word;
`
export default Details
