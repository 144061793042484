import { FetchApiOptions } from './fetchApi.types'
import { fetchApiOrThrow } from './fetchApiOrThrow'

export const fetchApiDataWithStatusOrThrow = async <T>(
  path: string,
  requestOptions?: RequestInit,
  options?: FetchApiOptions,
): Promise<{ status: number; data: T }> => {
  const response = await fetchApiOrThrow(path, requestOptions, options)
  const status = response.status
  const data = (await response.json()) as T

  return { status, data }
}

export type DataWithStatus<T> = { status: number; data: T }
