import { BasicErrorComponent } from '@components/atoms/Error/Error'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import * as y from 'yup'
import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { claimCodePaths, shareKeyPaths } from '@configs/urls'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { api } from '@services/api'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { useState } from 'react'
import { HttpError } from '@errors/httpError'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogMessages as ddMsgs } from '@constants/datadog'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { apiv1 } from '@services/apiv1'

const cadeauUrl = import.meta.env.VITE_CADEAU_URL

const getValidationSchema = (t: TFunction) =>
  y.object({
    code: y.string().required(t('forms.validation.required')),
  })

export const ShareKeyForm: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [errorOccurred, setErrorOccurred] = useState(false)

  const codeNotFound = t('codeNotFound')
  const somethingWentWrong = t('somethingWentWrong')

  const initialValues = { code: '' }

  function categorizeAndLogErrorToDD(e: unknown) {
    let notFound = false
    if (!(e instanceof Error)) {
      datadogLogs.logger.error(ddMsgs.notInstanceOfError)
    } else if (!(e instanceof HttpError)) {
      datadogLogs.logger.error(`${ddMsgs.unknownError} | ${e.name} | ${e.message}`)
    } else if (!(e.status === HttpCodes.get(HttpCodeNames.NotFound))) {
      datadogLogs.logger.error(`${ddMsgs.httpError} | ${e.name} | ${e.message}`)
    } else if (e.status === HttpCodes.get(HttpCodeNames.NotFound)) {
      notFound = true
    }

    return { notFound }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ code }, { setStatus }) => {
        setStatus()
        setErrorOccurred(false)

        let shareKeyNotFound = false
        let claimCodeNotFound = false

        try {
          await api.cardDetail(code)
          const processCodePath = generatePath(claimCodePaths.root, { claimCode: code })
          navigate(processCodePath)
        } catch (e: unknown) {
          const { notFound } = categorizeAndLogErrorToDD(e)
          claimCodeNotFound = notFound

          try {
            await api.receiveInfo(code)
            const cataloguePath = generatePath(shareKeyPaths.confetti, { shareKey: code })
            navigate(cataloguePath)
          } catch (e: unknown) {
            const { notFound: shopFlowShareKeyNotFound } = categorizeAndLogErrorToDD(e)

            try {
              await apiv1.getCard(code)
              window.location.href = `${cadeauUrl}?c=${code}&lng=${i18n.language}`
            } catch (e: unknown) {
              const { notFound: walletFlowShareKeyNotFound } = categorizeAndLogErrorToDD(e)

              setErrorOccurred(true)
              setStatus(somethingWentWrong)
              shareKeyNotFound = !!shopFlowShareKeyNotFound && !!walletFlowShareKeyNotFound

              if (claimCodeNotFound && shareKeyNotFound) {
                shareKeyNotFound = true
                setStatus(codeNotFound)
              }
            }
          }
        }
      }}
      validationSchema={getValidationSchema(t)}
    >
      {({ isSubmitting, status }) => {
        return (
          <WithTranslateFormErrors>
            <StyledForm>
              <CardNumberInput
                autoComplete="code"
                description={t('forms.fields.shareKey')}
                name="code"
                placeholder={t('forms.placeholders.shareKey')}
              />
              {!isSubmitting ? (
                <PrimaryButton iconRight={Icons.Gift} type="submit">
                  {t('forms.actions.openYourGift')}
                </PrimaryButton>
              ) : (
                <LoadingButton />
              )}
              <ErrorComponent>
                {errorOccurred && (
                  <BasicErrorComponent onClose={() => setErrorOccurred(false)}>
                    {status ? t(status) : t('somethingWentWrong')}
                  </BasicErrorComponent>
                )}
              </ErrorComponent>
            </StyledForm>
          </WithTranslateFormErrors>
        )
      }}
    </Formik>
  )
}

const StyledForm = styled(Form)`
  width: 100%;
`

const ErrorComponent = styled(Fonts.BodyRegular)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px 0 8px 0;
  color: ${colours.error.red};
`

const CardNumberInput = styled(TextInput)`
  margin-bottom: 24px;
`
