import { getVoucherFetchConfig } from '@configs/api'
import { voucherPaths, vouchersBaseURL } from '@configs/urls'

import { fetchApiDataWithStatusOrThrow } from './fetchApiDataWithStatusOrThrow'

export const voucherApi = {
  essentVoucherClaim: async (payload: {
    code: string | number
  }): Promise<{ data: { message: string }; status: number }> => {
    const essentMarker = { organization_id: 1 }
    const body = JSON.stringify({ ...payload, ...essentMarker })
    const config = await getVoucherFetchConfig({ method: 'POST', body })

    return await fetchApiDataWithStatusOrThrow(voucherPaths.essentVoucherClaim(), config, {
      baseUrl: vouchersBaseURL,
      retries: 0,
    })
  },
}
