import { PATHS, PATH_PARAMS, SUBPATHS } from '@constants/paths'

export const apiv1BaseURL = import.meta.env.VITE_APIV1_ENDPOINT
export const apiBaseURL = import.meta.env.VITE_API_ENDPOINT
export const apiTranslationsURL = import.meta.env.VITE_TRANSLATIONS_ENDPOINT
export const vouchersBaseURL = import.meta.env.VITE_VOUCHER_URL

const apiVersions = { v1: 'v1', v2: 'v2' }

export const voucherPaths = {
  essentVoucherClaim: () => `api/vouchers/claim/`,
}

export const apiv1Paths = {
  check: () => `${apiVersions.v1}/wallet/receive/check/`,
  walletReceive: (shareKey: string) => `${apiVersions.v1}/wallet/receive/${shareKey}/public/`,
}

export const apiPaths = {
  claimCodeCardAdd: (claim_code: string) => `${apiVersions.v2}/shop/card/${claim_code}/add/`,
  claimCodeCardDetail: (claim_code: string) => `${apiVersions.v2}/shop/card/${claim_code}/`,
  claimCodeCardUpdateBalance: (claim_code: string) =>
    `${apiVersions.v2}/shop/card/${claim_code}/update_balance/`,
  claimCodeCheckCardFlow: (claim_code: string) =>
    `${apiVersions.v2}/shop/card/${claim_code}/check/`,
  claimCodeClaim: (claim_code: string) => `${apiVersions.v2}/shop/card/${claim_code}/claim/`,
  claimCodeShopCatalogue: (claim_code: string) =>
    `${apiVersions.v2}/shop/card/${claim_code}/available-products/`,
  orderIdShopOrderDetail: (orderId: string) => `${apiVersions.v2}/shop/order/${orderId}/`,
  shareKeyClaim: (share_key: string) => `${apiVersions.v2}/shop/receive/${share_key}/claim/`,
  shareKeyInfo: (share_key: string) => `${apiVersions.v2}/shop/receive/${share_key}/`,
  shopOrder: () => `${apiVersions.v2}/shop/order/`,
  shopSurvey: () => `${apiVersions.v2}/survey/`,
  shareKeyByCodes: () => `${apiVersions.v2}/shop/receive/check/`,
  activate: (share_key: string) => `${apiVersions.v2}/shop/redeem/${share_key}/`,
  calculateFee: (pk: string | number) => `${apiVersions.v2}/shop/catalogue/${pk}/calculate-fee/`,
  calculateFees: () => `${apiVersions.v2}/shop/order/calculate-fees/`,
  referralDetail: (referralCode: string | number) =>
    `${apiVersions.v2}/panel-referral/${referralCode}`,
  referralSignUp: (referralCode: string | number) =>
    `${apiVersions.v2}/panel-referral/${referralCode}/sign-up/`,
  createReferralCode: (claimCode: string) =>
    `${apiVersions.v2}/shop/card/${claimCode}/create-referral-code/`,
  listPanels: () => `${apiVersions.v2}/panel/`,
  panelSignUp: () => `${apiVersions.v2}/panel/sign-up/`,
}

export const shareKeyPaths = {
  confetti: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.confetti}`,
  claim: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.claim}`,
  claimSkip: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.claimSkip}`,
  preparingChoiceCard: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.preparingChoiceCard}`,
  account: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.account}`,
  signUp: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.signUp}`,
}

export const claimCodePaths = {
  account: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.account}`,
  catalogue: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.catalogue}`,
  product: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.catalogue}/${PATH_PARAMS.productId}`,
  claimCodeSelect: `/${PATHS.card}/${PATHS.claimCodeSelect}`,
  enter: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.enterCode}`,
  root: `/${PATHS.card}/${PATH_PARAMS.claimCode}`,
  signUp: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.signUp}`,
  welcome: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.welcome}`,
}

export const cartSteps = {
  main: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}`,
  overview: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}`,
  checkout: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}/${PATHS.checkout}`,
  checkoutReview: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}/${PATHS.checkout}/${SUBPATHS.checkoutReview}`,
  checkoutPaymentMethod: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}/${PATHS.checkout}/${SUBPATHS.checkoutPaymentMethod}`,
}

export const orderSteps = {
  orderSummary: `/${PATHS.order}/${PATH_PARAMS.id}`,
}

export const refer = {
  root: `/${PATHS.refer}`,
}

export const panels = {
  root: `/${PATHS.panels}`,
}
