import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { Accordion } from '@components/atoms/Accordion/Accordion'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { TitleHeaderH1LCSS, TitleHeaderH1SCSS } from '@components/atoms/typography/css'

import { Fonts } from '@components/atoms/typography/Fonts'
import { CardStack } from '@components/molecules/CardStack/CardStack'
import { SignUpForm } from '@components/organisms/forms/SignUpForm/SignUpForm'
import { colours } from '@configs/colours'
import { useData } from '@hooks/useData'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { api } from '@services/api'
import { ReceiveInfo } from '@services/api.types'
import { mediaQueries } from '@utils/mediaQueries'

import CheckmarkGreen from '/images/checkmark-green.png'

// Uncomment when backend endpoint ready
const ENABLE_SIGNUP = false

const getDelay = (
  timeMinutes?: number | null,
): [number, 'days' | 'hours' | 'minutes' | 'unknown'] => {
  if (!timeMinutes) return [-1, 'unknown']
  if (timeMinutes >= 1440) return [Math.ceil(timeMinutes / 60 / 24), 'days']
  if (timeMinutes >= 60) return [Math.ceil(timeMinutes / 60), 'hours']
  return [Math.ceil(timeMinutes), 'minutes']
}

const ChoiceCard: React.FC = () => {
  const { i18n, t } = useTranslation()
  const { shareKey = '' } = useParams()
  const { hashFlows } = useHashFlowContext()

  const [about, setAbout] = useState(false)
  const [validity, setValidity] = useState(false)
  const [howItWorks, setHowItWorks] = useState(false)

  const emailDelay = hashFlows[shareKey]?.claimCodeDelayMinutes

  const [claimDelay, timeUnit] = getDelay(emailDelay)

  const { data } = useData<ReceiveInfo>(
    useCallback(() => api.receiveInfo(shareKey, i18n.language), [i18n.language, shareKey]),
  )

  return (
    <Root>
      <MainContent>
        <WidthBoundary>
          <TitleHeader>{t('yay')}</TitleHeader>
          <GrayCircleWrapper>
            <GrayCircle>
              <img src={CheckmarkGreen} />
            </GrayCircle>
          </GrayCircleWrapper>
          <MainInfo>{t('claimBeingProcessed')}</MainInfo>
          <InfoDescription>{t('claimBeingProcessedDescription')}</InfoDescription>
        </WidthBoundary>
      </MainContent>
      <WidthBoundary>
        <BulletPointsAndEncouragement>
          <BulletPointsWrapper>
            <BulletsTitle>{t('nextSteps')}</BulletsTitle>
            <BulletPoints>
              <Bullet>1</Bullet>
              <BulletPointsText>
                {t('afterClaimSteps.receiveChoiceCardByEmail')}
                {timeUnit === 'days' && (
                  <BulletPointsText $lighter as="div">
                    {t('afterClaimSteps.itCanTakeDaysYourCard', { count: claimDelay })}
                  </BulletPointsText>
                )}
                {timeUnit === 'hours' && (
                  <BulletPointsText $lighter as="div">
                    {t('afterClaimSteps.itCanTakeHoursYourCard', { count: claimDelay })}
                  </BulletPointsText>
                )}
                {timeUnit === 'minutes' && (
                  <BulletPointsText $lighter as="div">
                    {t('afterClaimSteps.itCanTakeMinutesYourCard', { count: claimDelay })}
                  </BulletPointsText>
                )}
              </BulletPointsText>
            </BulletPoints>
            <BulletPoints>
              <Bullet>2</Bullet>
              <BulletPointsText>{t('afterClaimSteps.exchangeChoiceCard')}</BulletPointsText>
            </BulletPoints>
            <BulletPoints>
              <Bullet>3</Bullet>
              <BulletPointsText>{t('afterClaimSteps.receiveChosenCardByEmail')}</BulletPointsText>
            </BulletPoints>
          </BulletPointsWrapper>
          {ENABLE_SIGNUP && (
            <SignUpFormWrapper>
              <SignUpEncouragement>{t('whyNotSignUp')}</SignUpEncouragement>
              <SignUpForm />
            </SignUpFormWrapper>
          )}
        </BulletPointsAndEncouragement>
      </WidthBoundary>
      <AboutChoiceCard>
        <WidthBoundary>
          <MobileOnly>
            {data?.product?.primary_image && (
              <ImgWrapper>
                <Img src={data.product.primary_image} />
              </ImgWrapper>
            )}
            <WhiteH2S>{t('yourChoiceCard')}</WhiteH2S>
            <StyledAccordion
              chevronColor={colours.prisma.white}
              expanded={about}
              onExpandedChange={() => setAbout((v) => !v)}
              titleText={<Fonts.ButtonCTALabelLarge>{t('about')}</Fonts.ButtonCTALabelLarge>}
            >
              <AccordionText>{data?.product?.description_short}</AccordionText>
            </StyledAccordion>
            <Hr />
            <StyledAccordion
              chevronColor={colours.prisma.white}
              expanded={howItWorks}
              onExpandedChange={() => setHowItWorks((v) => !v)}
              titleText={<Fonts.ButtonCTALabelLarge>{t('howItWorks')}</Fonts.ButtonCTALabelLarge>}
            >
              <AccordionText>{data?.product?.spending_text}</AccordionText>
            </StyledAccordion>
            <Hr />
            <StyledAccordion
              chevronColor={colours.prisma.white}
              expanded={validity}
              onExpandedChange={() => setValidity((v) => !v)}
              titleText={<Fonts.ButtonCTALabelLarge>{t('validity')}</Fonts.ButtonCTALabelLarge>}
            >
              <AccordionText>{data?.product?.expiration_text}</AccordionText>
            </StyledAccordion>
          </MobileOnly>
          <FromMobileL>
            <Arranger>
              <CardStackWrapper>
                <CardStack slotImage={data?.product?.primary_image} />
              </CardStackWrapper>
              <AboutWrapper>
                <AboutTitle>{t('yourChoiceCard')}</AboutTitle>
                <WhiteH2S>{t('about')}</WhiteH2S>
                <AboutPointsText>{data?.product?.description_short}</AboutPointsText>
                <WhiteH2S>{t('howItWorks')}</WhiteH2S>
                <AboutPointsText>{data?.product?.spending_text}</AboutPointsText>
                <WhiteH2S>{t('validity')}</WhiteH2S>
                <AboutPointsText>{data?.product?.expiration_text}</AboutPointsText>
              </AboutWrapper>
            </Arranger>
          </FromMobileL>
        </WidthBoundary>
      </AboutChoiceCard>
      <AboutFooter />
    </Root>
  )
}

export default ChoiceCard

const SignUpEncouragement = styled(Fonts.MiscelaneousBig)`
  margin: 0 0 48px 0;
`

const SignUpFormWrapper = styled.div`
  padding: 32px 24px 24px 24px;
  ${mediaQueries.from.breakpoint.desktop} {
    box-shadow: 0px 16px 20px rgba(163, 163, 163, 0.25);
    border-radius: 24px;
  }
`

const BulletPointsWrapper = styled.div`
  margin-bottom: 56px;
  max-width: 400px;
`

const Arranger = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const AboutWrapper = styled.div``

const CardStackWrapper = styled.div`
  width: 100%;
  margin-top: 88px;
  margin-bottom: 40px;
`

const AboutTitle = styled(Fonts.TitleHeaderH1S)`
  color: ${colours.prisma.white};
  margin-bottom: 24px;
`

const FromMobileL = styled.div`
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`

const Img = styled.img`
  width: 265px;
  margin-bottom: 40px;
`

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Hr = styled.div`
  border-top: 1px solid ${colours.mist[800]};
  margin-top: 24px;
  margin-bottom: 24px;
`

const WhiteH2S = styled(Fonts.TitleHeaderH2)`
  color: ${colours.prisma.white};
  margin-bottom: 8px;
`

const MobileOnly = styled.div`
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
`

const StyledAccordion = styled(Accordion)``

const AccordionText = styled(Fonts.BodyRegular)`
  color: ${colours.mist[400]};
  padding: 8px 0;
`

const BulletPoints = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
`

const BulletPointsText = styled(Fonts.BodyLarge)<{ $lighter?: boolean }>`
  color: ${(p) => (p.$lighter ? colours.mist[500] : colours.mist[800])};
`

const AboutPointsText = styled(Fonts.BodyLarge)<{ $lighter?: boolean }>`
  color: ${colours.mist[500]};
  margin-bottom: 24px;
`

const Bullet = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.prisma.white};
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: ${colours.brand.green};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`

const BulletsTitle = styled(Fonts.TitleHeaderH2)`
  margin-bottom: 24px;
`

const GrayCircleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const InfoDescription = styled(Fonts.BodyLarge)`
  text-align: center;
`

const MainInfo = styled(Fonts.ParagraphTitle)`
  margin-bottom: 8px;
  text-align: center;
`

const TitleHeader = styled.div`
  margin-bottom: 32px;
  text-align: center;

  ${mediaQueries.from.breakpoint.desktop} {
    ${TitleHeaderH1LCSS}
  }

  ${mediaQueries.to.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`

const MainContent = styled.div`
  background: linear-gradient(180deg, #fff 0%, #f4f6f7 50%, #edf0f1 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 64px 0;
  margin-bottom: 40px;
  ${mediaQueries.from.breakpoint.desktop} {
    padding: 112px 0 160px 0;
  }
`

const GrayCircle = styled.div`
  align-items: center;
  background-color: ${colours.mist[200]};
  border-radius: 100%;
  display: flex;
  gap: 8px;
  height: 200px;
  justify-content: center;
  width: 200px;
  ${mediaQueries.to.breakpoint.desktop} {
    margin-bottom: 24px;
  }
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 32px;
  }
`

const BulletPointsAndEncouragement = styled.div`
  margin-bottom: 72px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 80px;
  justify-items: center;
`

const AboutChoiceCard = styled.div`
  background: ${colours.brand.dark};
  color: white;
  padding: 40px 0 56px 0;
`
