import { getFetchConfig } from '@configs/api'
import { apiv1Paths } from '@configs/urls'

import { fetchApiDataOrThrow } from './fetchApiDataOrThrow'

export const apiv1 = {
  checkNumberAndPIN: async (
    card_number: string,
    security_code: string,
  ): Promise<{ share_key: string }> => {
    const body = { card_number, security_code }
    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify(body),
      noAuth: true,
    })

    return await fetchApiDataOrThrow(apiv1Paths.check(), config)
  },
  getCard: async (shareKey: string): Promise<unknown> => {
    const config = await getFetchConfig({
      method: 'GET',
      noAuth: true,
    })

    return await fetchApiDataOrThrow(apiv1Paths.walletReceive(shareKey), config)
  },
}
