import { useFormikContext } from 'formik'
import { produce } from 'immer'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { FromMobileL } from '@components/atoms/checkout/FromMobileL'
import { Ripped } from '@components/atoms/checkout/Ripped'
import { Root } from '@components/atoms/checkout/Root'
import { VisibleFromMobile } from '@components/atoms/checkout/VisibleFromMobile'
import { VisibleToMobile } from '@components/atoms/checkout/VisibleToMobile'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { DesktopBannersGroup } from '@components/molecules/card/[claim-code]/cart/overview/DesktopBannersGroup'
import { MobileBannersGroup } from '@components/molecules/card/[claim-code]/cart/overview/MobileBannersGroup'
import { TabletBannersGroup } from '@components/molecules/card/[claim-code]/cart/overview/TabletBannersGroup'
import { BottomActionsSection } from '@components/molecules/checkout/BottomActionsSection'
import { CheckoutGridArrangement } from '@components/molecules/checkout/CheckoutGridArrangement'
import { Cta } from '@components/molecules/checkout/Cta'
import { PaymentSelector } from '@components/molecules/checkout/PaymentSelector'
import { ReceiptSection } from '@components/molecules/checkout/ReceiptSection'
import { TitleWithProgressSection } from '@components/molecules/checkout/TitleWithProgressSection'
import { TopSection } from '@components/molecules/checkout/TopSection'
import { orderSteps } from '@configs/urls'
import {
  orderFailedStatuses,
  orderPendingStatuses,
  orderSuccessStatuses,
} from '@constants/orderStatuses'
import { supportedIdealCurrencies } from '@constants/paymentMethods'
import {
  paymentFailedStatuses,
  paymentPendingStatuses,
  paymentSuccessStatuses,
} from '@constants/paymentStatuses'
import { useCheckoutContext } from '@hooks/useCheckout'
import { useCartContext } from '@hooks/useCart'
import { api } from '@services/api'
import { TrengoCheckoutStyle } from '@styles/TrengoCheckoutStyle'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { LimitsViolations } from '@utils/evaluateLimits'
import { breakpoints, mediaQueries } from '@utils/mediaQueries'

const RETRY_COUNT = 4
const RETRY_TIMEOUT = 2000

type Props = {
  balanceNotValid: boolean
  currency: string | undefined
  dismissViolationError: () => void
  error?: boolean | string
  orderSubmitted: boolean
  setError?: (arg: boolean) => void
  violationError?: LimitsViolations
}

export const Payment: React.FC<PropsWithChildren<Props>> = ({
  balanceNotValid,
  currency,
  dismissViolationError,
  error,
  orderSubmitted,
  setError,
  violationError,
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { values, setErrors, errors, isSubmitting } = useFormikContext<CheckoutFormikValues>()
  const { isMainChoiceCardEmpty } = useCartContext()
  const { amountFromChoiceCards, getFeesFromBackend, priceFromCards, getToPay } =
    useCheckoutContext()

  const [isMobile, setIsMobile] = useState(false)
  const [orderProcessing, setOrderProcessing] = useState(!!orderId)
  const [orderError, setOrderError] = useState(false)
  const [touched, setTouched] = useState(false)
  const [paymentMethodBannerClosed, setPaymentMethodBannerClosed] = useState(false)
  const [takesLongerWarning, setTakesLongerWarning] = useState(false)

  const retries = useRef(RETRY_COUNT)

  const toPay = getToPay(values.choiceCardsToUse[0]?.currency ?? values.cards[0]?.currency)

  useEffect(() => {
    const noteRetry = () => {
      retries.current = Math.max(0, retries.current - 1)
      if (retries.current <= 0) setTakesLongerWarning(true)
    }

    const getShopOrder = (): void => {
      if (!orderId) return

      setOrderProcessing(true)
      setOrderError(false)

      api
        .shopOrderDetail(orderId, i18n.language)
        .then((orderData) => {
          const paymentStatus = orderData.payment?.status
          const orderStatus = orderData.status

          const paymentFailed = !paymentStatus || paymentFailedStatuses.includes(paymentStatus)
          const orderFailed = !orderStatus || orderFailedStatuses.includes(orderStatus)
          const paymentPending = paymentStatus && paymentPendingStatuses.includes(paymentStatus)
          const orderPending = orderStatus && orderPendingStatuses.includes(orderStatus)
          const orderSucceeded = orderStatus && orderSuccessStatuses.includes(orderStatus)
          const paymentSucceeded = paymentStatus && paymentSuccessStatuses.includes(paymentStatus)

          if (paymentFailed || orderFailed) {
            setOrderError(true)
            setOrderProcessing(false)
            return
          }

          if (paymentPending || orderPending) {
            noteRetry()
            setTimeout(() => getShopOrder(), RETRY_TIMEOUT)
            return
          }

          if (paymentSucceeded && orderSucceeded) {
            navigate(generatePath(orderSteps.orderSummary, { id: orderId }))
            return
          }

          const module = '[Ordering]'
          const reason = 'Unhandled ordering scenario.'
          const info = `Payment status '${paymentStatus}', order status '${orderStatus}'`

          throw Error(`${module} ${reason} ${info}`)
        })
        .catch((e: unknown) => {
          console.error(e)
          setOrderError(true)
          setOrderProcessing(false)
        })
    }

    getShopOrder()
  }, [orderId, i18n.language, navigate])

  useEffect(() => {
    if (window.innerWidth < breakpoints.mobileL) setIsMobile(true)
    function listenerCallback() {
      if (window.innerWidth < breakpoints.mobileL) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    window.addEventListener('resize', listenerCallback)

    return () => window.removeEventListener('resize', listenerCallback)
  }, [])

  const paymentMethodChoiceDisabled =
    typeof priceFromCards !== 'number' ||
    typeof amountFromChoiceCards !== 'number' ||
    priceFromCards - amountFromChoiceCards <= 0

  const ctaDisabled =
    isSubmitting ||
    values.cards.length === 0 ||
    !!errors.email ||
    !values.email ||
    (!values.paymentMethod && !paymentMethodChoiceDisabled) ||
    !!orderProcessing ||
    !!orderSubmitted ||
    isMainChoiceCardEmpty() ||
    balanceNotValid

  const submitText = isSubmitting
    ? t('forms.actions.submitting')
    : isMainChoiceCardEmpty()
    ? t('mainChoiceCardEmpty')
    : balanceNotValid
    ? t('balanceNotValid')
    : toPay === 0
    ? t('forms.actions.order')
    : t('forms.actions.orderAndPay')

  return (
    <Root>
      <TrengoCheckoutStyle />
      <FullHeight>
        <VisibleToMobile>
          <Ripped>
            <WidthBoundary>
              <TopSection />
            </WidthBoundary>
            <WidthBoundary>
              <TitleWithProgressSection count={3} step={2} />
            </WidthBoundary>
            <MobileBanners>
              <MobileBannersGroup
                currency={currency}
                dismissViolationError={dismissViolationError}
                error={error}
                errors={errors}
                orderError={orderError}
                paymentMethodBannerClosed={paymentMethodBannerClosed}
                setError={setError}
                setOrderError={setOrderError}
                setPaymentMethodBannerClosed={setPaymentMethodBannerClosed}
                takesLongerWarning={takesLongerWarning}
                touched={touched}
                violationError={violationError}
              />
            </MobileBanners>
            <WidthBoundary>
              {isMobile && (
                <PaymentSelector
                  idealDisabled={!!currency && !(currency in supportedIdealCurrencies)}
                  paymentMethodChoiceDisabled={paymentMethodChoiceDisabled}
                  paymentRequired={toPay !== 0}
                />
              )}
            </WidthBoundary>
          </Ripped>
          <ReceiptSection currency={currency} />
          <BottomActionsSection
            actionDisabled={ctaDisabled}
            actionHandler={() => setErrors({})}
            currency={currency}
            iconRight={ctaDisabled ? undefined : Icons.ArrowRight}
            loading={orderProcessing || isSubmitting}
            text={submitText}
            type="submit"
          />
        </VisibleToMobile>
        <VisibleFromMobile>
          <WidthBoundary>
            <TopSection>
              <DesktopBannersGroup
                currency={currency}
                dismissViolationError={dismissViolationError}
                error={error}
                errors={errors}
                orderError={orderError}
                paymentMethodBannerClosed={paymentMethodBannerClosed}
                setError={setError}
                setOrderError={setOrderError}
                setPaymentMethodBannerClosed={setPaymentMethodBannerClosed}
                takesLongerWarning={takesLongerWarning}
                touched={touched}
                violationError={violationError}
              />
            </TopSection>
            <TitleWithProgressSection count={3} step={2} />
            <TabletBannersGroup
              currency={currency}
              dismissViolationError={dismissViolationError}
              error={error}
              errors={errors}
              orderError={orderError}
              paymentMethodBannerClosed={paymentMethodBannerClosed}
              setError={setError}
              setOrderError={setOrderError}
              setPaymentMethodBannerClosed={setPaymentMethodBannerClosed}
              takesLongerWarning={takesLongerWarning}
              touched={touched}
              violationError={violationError}
            />
            <CheckoutGridArrangement>
              {!isMobile && (
                <PaymentMethodsWrapper>
                  <PaymentSelector
                    idealDisabled={!!currency && !(currency in supportedIdealCurrencies)}
                    onValueChange={(newValue) => {
                      const updated = produce(values, (draft) => {
                        if (newValue === 'ideal' || newValue === 'creditcard')
                          draft.paymentMethod = newValue
                      })

                      void getFeesFromBackend(updated)
                    }}
                    paymentMethodChoiceDisabled={paymentMethodChoiceDisabled}
                    paymentRequired={toPay !== 0}
                  />
                </PaymentMethodsWrapper>
              )}
              <ExtendedReceipt style={{ gridArea: 'extendedReceipt' }}>
                <ReceiptSection currency={currency} />
                <Cta
                  actionDisabled={ctaDisabled}
                  actionHandler={() => {
                    setTouched(true)
                    setPaymentMethodBannerClosed(false)
                  }}
                  iconRight={ctaDisabled ? undefined : Icons.ArrowRight}
                  loading={orderProcessing || isSubmitting}
                  text={submitText}
                  type="submit"
                />
              </ExtendedReceipt>
            </CheckoutGridArrangement>
          </WidthBoundary>
        </VisibleFromMobile>
        <FromMobileL>
          <AboutFooter $noBackground />
        </FromMobileL>
      </FullHeight>
    </Root>
  )
}

const PaymentMethodsWrapper = styled.div`
  grid-area: simpleReceipt;
  margin-bottom: 16px;
  ${mediaQueries.within.breakpoint.tablet} {
    grid-area: delivery;
  }
`

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  position: relative;
  justify-content: space-between;
`

const ExtendedReceipt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const MobileBanners = styled.div``
