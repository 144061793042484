import { useFormikContext } from 'formik'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { Ripped } from '@components/atoms/checkout/Ripped'
import { Root } from '@components/atoms/checkout/Root'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { BottomActionsSection } from '@components/molecules/checkout/BottomActionsSection'
import { CheckoutGridArrangement } from '@components/molecules/checkout/CheckoutGridArrangement'
import { Cta } from '@components/molecules/checkout/Cta'
import { DeliverySection } from '@components/molecules/checkout/DeliverySection'
import { ReceiptSection } from '@components/molecules/checkout/ReceiptSection'
import { SimpleSummarySection } from '@components/molecules/checkout/SimpleSummarySection'
import { TitleWithProgressSection } from '@components/molecules/checkout/TitleWithProgressSection'
import { TopSection } from '@components/molecules/checkout/TopSection'
import { cartSteps } from '@configs/urls'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useProductsContext } from '@hooks/useProducts'
import { useSettingsContext } from '@hooks/useSettings'
import { TrengoCheckoutStyle } from '@styles/TrengoCheckoutStyle'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'
import { useCartContext } from '@hooks/useCart'

type Props = {
  currency: string | undefined
}

export const Review: React.FC<PropsWithChildren<Props>> = () => {
  const { t } = useTranslation()
  const { mainKey } = useMainKeyContext()
  const navigate = useNavigate()
  const { values, setErrors, errors, validateForm } = useFormikContext<CheckoutFormikValues>()
  const { reloadShopConfig } = useSettingsContext()
  const { reloadProducts } = useProductsContext()
  const { isMainChoiceCardEmpty, saveCart } = useCartContext()

  const [isProcessing, setIsProcessing] = useState(false)

  const navigationHandler = async () => {
    try {
      setIsProcessing(true)

      await reloadProducts()
      await reloadShopConfig()
      saveCart()
    } catch {
      console.warn('[Navigation] Cannot reload data')
    } finally {
      setErrors({})
      const { email: emailError } = await validateForm()
      if (!emailError) {
        navigate(
          generatePath(cartSteps.checkoutPaymentMethod, {
            claimCode: mainKey,
          }),
        )
      }

      setIsProcessing(false)
    }
  }

  return (
    <Root>
      <TrengoCheckoutStyle />
      <FullHeight>
        <VisibleToMobile>
          <Ripped>
            <TopSection />
            <WidthBoundary>
              <TitleWithProgressSection count={3} step={1} />
            </WidthBoundary>
            <WidthBoundary>
              <MobileSummaryAndDeliveryArrangement>
                <SimpleSummarySection />
                <DeliverySection />
              </MobileSummaryAndDeliveryArrangement>
            </WidthBoundary>
          </Ripped>
          <ReceiptSection currency={values.cards[0]?.currency} />
          <BottomActionsSection
            actionDisabled={
              values.cards.length === 0 ||
              !!errors.email ||
              !values.email ||
              isProcessing ||
              isMainChoiceCardEmpty()
            }
            actionHandler={navigationHandler}
            currency={values.cards[0]?.currency}
            iconRight={Icons.ArrowRight}
            text={isMainChoiceCardEmpty() ? t('mainChoiceCardEmpty') : t('forms.actions.confirm')}
          />
        </VisibleToMobile>
        <VisibleFromMobile>
          <WidthBoundary>
            <TopSection />
          </WidthBoundary>
          <WidthBoundary>
            <TitleWithProgressSection count={3} step={1} />
            <CheckoutGridArrangement>
              <SimpleSummarySection style={{ gridArea: 'simpleReceipt' }} />
              <DeliverySection style={{ gridArea: 'delivery' }} />
              <ExtendedReceipt style={{ gridArea: 'extendedReceipt' }}>
                <ReceiptSection currency={values.cards[0]?.currency} />
                <Cta
                  actionDisabled={
                    values.cards.length === 0 ||
                    !!errors.email ||
                    !values.email ||
                    isProcessing ||
                    isMainChoiceCardEmpty()
                  }
                  actionHandler={navigationHandler}
                  iconRight={Icons.ArrowRight}
                  text={
                    isMainChoiceCardEmpty() ? t('mainChoiceCardEmpty') : t('forms.actions.confirm')
                  }
                />
              </ExtendedReceipt>
            </CheckoutGridArrangement>
          </WidthBoundary>
        </VisibleFromMobile>
        <FromMobileL>
          <AboutFooter $noBackground />
        </FromMobileL>
      </FullHeight>
    </Root>
  )
}

const FromMobileL = styled.div`
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`

const ExtendedReceipt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const MobileSummaryAndDeliveryArrangement = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
`

const VisibleToMobile = styled.div`
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
`

const VisibleFromMobile = styled.div`
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  position: relative;
  justify-content: space-between;
`
