import { Form, Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import { CartNotInitialized } from '@components/molecules/CartNotInitialized/CartNotInitialized'
import { Cart as CartOrganism } from '@components/organisms/Checkout/Cart'
import { Payment as PaymentOrganism } from '@components/organisms/Checkout/Payment'
import { Review as ReviewOrganism } from '@components/organisms/Checkout/Review'
import { claimCodePaths } from '@configs/urls'
import { PATHS, SUBPATHS } from '@constants/paths'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useCartContext } from '@hooks/useCart'
import { useCheckoutContext } from '@hooks/useCheckout'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useProductsContext } from '@hooks/useProducts'
import { useUserContext } from '@hooks/useUserContext'
import { getValidationSchema } from '@schemas/pages/card/[claim-code]/cart/overview/schema'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'
import { LimitsViolations } from '@utils/evaluateLimits'
import { getMainChoiceCardCurrency } from '@utils/getMainChoiceCardCurrency'
import { useSettingsContext } from '@hooks/useSettings'
import { onSubmit } from '@services/pages/card/[claim-code]/cart/overview/onSubmit'

const CartOverview: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const {
    aggregatedCart,
    clearCart,
    getOrderId,
    checkIfChoiceCardBalancesUpToDate,
    saveCart,
    setCart,
    setOrderId,
    isMainChoiceCardEmpty,
  } = useCartContext()
  const { mainKey } = useMainKeyContext()
  const { productsErrorCode, reloadProducts } = useProductsContext()
  const { isLoggedIn, email } = useUserContext()
  const { hashFlows } = useHashFlowContext()
  const { setCheckoutValues } = useCheckoutContext()
  const { panel, referralCode, setReferralCode, setPanel } = useSettingsContext()

  const [orderSubmitted, setOrderSubmitted] = useState(false)
  const [error, setError] = useState<string | boolean | undefined>(false)
  const [violationError, setViolationError] = useState<LimitsViolations>()
  const [balanceNotValid, setBalanceNotValid] = useState(false)

  const persistForm = useRef(true)

  const dismissViolationError = () => setViolationError(undefined)

  const getInitialEmail = (): string => {
    if (isLoggedIn) return email
    if (mainKey) return hashFlows[mainKey]?.prefillEmail ?? ''
    return ''
  }

  const isMainCart = !Object.values(params).includes(PATHS.checkout)
  const isReviewCheckout = Object.values(params).includes(SUBPATHS.checkoutReview)
  const isPaymentCheckout = Object.values(params).includes(SUBPATHS.checkoutPaymentMethod)

  if (mainKey === null) return <CartNotInitialized />
  const currentCart = aggregatedCart.carts[mainKey]
  if (!currentCart) return

  const initialCheckoutValues: CheckoutFormikValues = {
    ...currentCart,
    email: currentCart.email || getInitialEmail() || '',
    choiceCardInput: '',
    paymentMethod: '',
  }

  if (
    productsErrorCode === HttpCodes.get(HttpCodeNames.Forbidden) ||
    productsErrorCode === HttpCodes.get(HttpCodeNames.Unauthroized) ||
    mainKey !== params.claimCode
  ) {
    return <Navigate to={generatePath(claimCodePaths.root, { claimCode: params.claimCode })} />
  }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialCheckoutValues}
      onSubmit={onSubmit({
        clearCart,
        email,
        getOrderId,
        isMainChoiceCardEmpty,
        mainKey,
        navigate,
        panel,
        persistForm,
        referralCode,
        checkIfChoiceCardBalancesUpToDate,
        reloadProducts,
        saveCart,
        setBalanceNotValid,
        setError,
        setOrderId,
        setOrderSubmitted,
        setPanel,
        setReferralCode,
        setViolationError,
      })}
      validateOnChange
      validationSchema={getValidationSchema(t)}
    >
      {({ values }) => {
        setCart(values)
        setCheckoutValues(values)

        return (
          <Root>
            <TrengoWidgetStyle />
            <WithTranslateFormErrors>
              <StyledForm>
                {isMainCart && (
                  <CartOrganism currency={getMainChoiceCardCurrency(aggregatedCart, mainKey)} />
                )}

                {isReviewCheckout && (
                  <ReviewOrganism currency={getMainChoiceCardCurrency(aggregatedCart, mainKey)} />
                )}

                {isPaymentCheckout && (
                  <PaymentOrganism
                    currency={getMainChoiceCardCurrency(aggregatedCart, mainKey)}
                    dismissViolationError={dismissViolationError}
                    error={error}
                    orderSubmitted={orderSubmitted}
                    setError={setError}
                    violationError={violationError}
                    balanceNotValid={balanceNotValid}
                  />
                )}
              </StyledForm>
            </WithTranslateFormErrors>
          </Root>
        )
      }}
    </Formik>
  )
}

const TrengoWidgetStyle = createGlobalStyle`
  div#trengo-web-widget {
    display: none;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  width: 100%;
`

const StyledForm = styled(Form)`
  flex-grow: 10;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`

export default CartOverview
