import { signOut } from 'aws-amplify/auth'
import { Form, Formik } from 'formik'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import { DummyGiftCardVertical } from '@components/atoms/cards/dummies/DummyGiftCardVertical'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { Fonts } from '@components/atoms/typography/Fonts'
import { BrandLightHeader } from '@components/molecules/BrandLightHeader/BrandLightHeader'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { GeneralError } from '@components/molecules/forms/GeneralError/GeneralError'
import { CatalogueOrganism } from '@components/organisms/Catalogue/Catalogue'
import { colours } from '@configs/colours'
import { claimCodePaths } from '@configs/urls'
import { capitalizedLanguageMap } from '@constants/language'
import { zIndexes } from '@constants/zIndexes'
import { HttpError } from '@errors/httpError'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useCartContext } from '@hooks/useCart'
import { useData } from '@hooks/useData'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useSettingsContext } from '@hooks/useSettings'
import { useUserContext } from '@hooks/useUserContext'
import { api } from '@services/api'

import { Flows } from '@services/api.types'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { mediaQueries } from '@utils/mediaQueries'
import { parseConfigValues } from '@utils/parseConfigValues'
import { TitleHeaderH1SCSS } from '@components/atoms/typography/css'

const Welcome: React.FC = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { claimCode = '' } = useParams()
  const { addChoiceCard, saveCart } = useCartContext()
  const { setMainKey } = useMainKeyContext()
  const { isLoggedIn, setIsLoggedIn, setEmail, logOut } = useUserContext()
  const { dispatchHashFlows, hashFlows } = useHashFlowContext()
  const {
    languageSpecifics,
    setAllBranding,
    setDefaultBranding,
    setPanel,
    setReferralCode,
    setSlotImage,
    welcomeImage,
  } = useSettingsContext()

  const knownErrors = {
    wrongOwner: t('wrongOwner'),
  }

  const [generalError, setGeneralError] = useState(false)
  const [errorText, setErrorText] = useState<keyof typeof knownErrors | ''>('')

  const selectedLanguage = languageSpecifics?.find(
    (el) =>
      el.language === capitalizedLanguageMap[i18n.language as keyof typeof capitalizedLanguageMap],
  )
  const title = selectedLanguage?.welcome_title
  const text = selectedLanguage?.welcome_description

  const currentFlow = hashFlows[claimCode]?.flow
  const initialValues = { claimCode }
  const willBeLoggedOut = currentFlow === Flows.ShopGuest && isLoggedIn
  const mustLogin = currentFlow === Flows.ShopAccountMandatory && !isLoggedIn
  const {
    data: products,
    loading: productsLoading,
    error: productsError,
  } = useData(
    useCallback(() => api.catalogue(claimCode, i18n.language), [claimCode, i18n.language]),
    false,
  )

  const onSubmit = async ({ claimCode }: typeof initialValues) => {
    try {
      setErrorText('')
      if (mustLogin) navigate(generatePath(claimCodePaths.account, { claimCode }))

      if (willBeLoggedOut) {
        await signOut()
        setIsLoggedIn(false)
        setEmail('')
      }

      const data = await api.cardDetail(claimCode)

      const { maxPurchaseQuantity, maxPurchaseAmount } = parseConfigValues(data.config)
      const prefillEmail = data.prefill_email?.trim().toLowerCase()
      const newMainKey = data.claim_code ?? null
      const feePaymentOption = data.shop_fee_payment_option ?? null

      dispatchHashFlows({
        type: HashFlowActions.UpdateHashFlow,
        payload: {
          hash: claimCode,
          prefillEmail,
          maxPurchaseQuantity,
          maxPurchaseAmount,
          feePaymentOption,
        },
      })

      setMainKey(newMainKey)

      const initializedCart = addChoiceCard(
        {
          claim_code: data.claim_code ?? '',
          currency: data.product?.currency,
          debit_value: data.balance ? +data.balance : 0,
          name: data.product?.name,
        },
        newMainKey,
      )
      saveCart(initializedCart)

      setReferralCode(data.referral_code)
      setPanel(data.panel)

      if (!data.shop_personalization) {
        setDefaultBranding()
      } else {
        setAllBranding(data)
        setSlotImage(data.product?.primary_image)
      }

      navigate(generatePath(claimCodePaths.catalogue, { claimCode }))
    } catch (e) {
      if (e instanceof HttpError && e.status === 403) {
        setGeneralError(true)
        setErrorText('wrongOwner')
      }

      if (e instanceof TypeError) {
        setGeneralError(() => {
          throw e
        })
      }

      throw e
    }
  }

  return (
    <>
      <TrengoWidgetModifier />
      <Root>
        <BackgroundShop>
          {!productsError ? (
            <CatalogueOrganism
              products={products}
              productsErrorCode={productsError}
              productsLoading={productsLoading}
            />
          ) : (
            <Wrapper>
              <BrandLightHeader placeholderSlot />
              <WidthBoundary>
                <Title>Lorem ipsum</Title>
                <Grid>
                  {Array.from({ length: 12 }, (_, i) => i).map((el) => (
                    <DummyGiftCardVertical key={el} />
                  ))}
                </Grid>
              </WidthBoundary>
            </Wrapper>
          )}
        </BackgroundShop>
        <Section>
          <Overlay>
            <BrandImageWrapper $backgroundColor={colours.prisma.white}>
              {welcomeImage && <Img alt="Welcome logo" className="logo" src={welcomeImage} />}
            </BrandImageWrapper>
            <Content>
              <div>
                <Greeting>{title ?? t('hiThere')}</Greeting>
                <Subtitle>{text ?? t('welcomeSubtitle')}</Subtitle>
              </div>
              <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
                <WithTranslateFormErrors>
                  <Form>
                    <GeneralError
                      $visible={generalError}
                      onClick={() => {
                        setGeneralError(false)
                        setErrorText('')
                      }}
                    >
                      {errorText ? knownErrors[errorText] : null}
                    </GeneralError>
                    {errorText === 'wrongOwner' ? (
                      <StyledPrimaryButton
                        iconRight={Icons.ArrowRight}
                        onClick={async () => {
                          await logOut()
                          navigate(generatePath(claimCodePaths.root, { claimCode }))
                        }}
                        type="button"
                      >
                        {t('forms.actions.logOut')}
                      </StyledPrimaryButton>
                    ) : (
                      <StyledPrimaryButton iconRight={Icons.ArrowRight} type="submit">
                        {t('explore')}
                      </StyledPrimaryButton>
                    )}
                  </Form>
                </WithTranslateFormErrors>
              </Formik>
            </Content>
          </Overlay>
        </Section>
      </Root>
    </>
  )
}

const TrengoWidgetModifier = createGlobalStyle`
  div.TrengoWidgetLauncher__iframe {
    display: none;
  }
`

const BackgroundShop = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    height: 0;
    max-height: 0;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const Section = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  top: 0;
  left: 0;
  z-index: ${zIndexes.blurOverlay};
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
`

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 24px;
  ${mediaQueries.from.breakpoint.desktop} {
    flex-direction: row;
    width: 800px;
    height: 440px;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
`

const BrandImageWrapper = styled.div<{ $backgroundColor?: Maybe<string> }>`
  width: 100%;
  max-height: 440px;
  display: flex;
  justify-content: center;
  background-color: ${(p) => (p.$backgroundColor ? p.$backgroundColor : colours.prisma.white)};
  background-image: url(Shop);
  overflow: hidden;
  ${mediaQueries.from.breakpoint.desktop} {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    flex: 50% 1 1;
    box-sizing: border-box;
  }
`

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Content = styled.div`
  flex-grow: 10;
  background-color: white;
  border-radius: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px max(calc((100% / 2) - calc(1200px / 2)), 24px);
  ${mediaQueries.from.breakpoint.desktop} {
    padding: 48px 32px 32px 40px;
    flex: 50% 1 1;
    box-sizing: border-box;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    border-radius: 0;
  }
`

const Greeting = styled.div`
  ${TitleHeaderH1SCSS};
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 2ch;
`

const Subtitle = styled(Fonts.BodyLarge)`
  color: ${colours.mist[800]};
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 24px;
`

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 24px;
  margin: 12px 0;
`

const Wrapper = styled.div`
  width: 100%;
  font-family: 'Poppins';
  height: 100%;
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

export default Welcome
