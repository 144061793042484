import { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from 'react'

import { creditCardFactor } from '@constants/paymentProcessingFee'
import { useMainKeyContext } from '@hooks/useMainKey'
import { CalculateFeesResponse } from '@typeDeclarations/api/calculateFees'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { calculateCardValueWithFee } from '@utils/calculateCardValueWithFee'
import { getSummaryValues } from '@utils/getSummaryValues'

import { CartItem } from './cart'
import { threeStepsFeesEvaluator } from '@utils/threeStepsFeeEvaluator'

const itemsMapper = (card: CartItem) => ({
  id: card.id,
  amount: card.amount,
  currency: card.currency,
  denominatedValues: card.denominatedValues,
  feeFlat: card.feeFlat,
  feePaymentOption: card.feePaymentOption,
  feePercentage: card.feePercentage,
  quantity: card.quantity,
  title: card.name,
})

export type CheckoutItem = ReturnType<typeof itemsMapper>

export type CheckoutContextType = {
  amountFromChoiceCards: number | undefined
  balanceFromCards: number | undefined
  calculatedFees: CalculateFeesResponse | undefined
  choiceCardsCount: number | undefined
  getToPay: (currency?: string) => number | undefined
  getFeesFromBackend: (newValues?: CheckoutFormikValues) => void | Promise<void>
  items: CheckoutItem[] | undefined
  paymentProcessingFee: PaymentProcessingFee | undefined
  priceFromCards: number | undefined
  totalCardsCount: number | undefined
  setCheckoutValues: Dispatch<SetStateAction<CheckoutFormikValues | undefined>>
}

const defaultCtx = {
  amountFromChoiceCards: undefined,
  balanceFromCards: undefined,
  choiceCardsCount: undefined,
  paymentProcessingFee: undefined,
  priceFromCards: undefined,
  totalCardsCount: undefined,
  items: undefined,
  calculatedFees: undefined,
  getToPay: () => undefined,
  getFeesFromBackend: () => undefined,
  setCheckoutValues: () => undefined,
}

export const CheckoutContext = createContext<CheckoutContextType>(defaultCtx)

export const CheckoutContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { mainKey } = useMainKeyContext()
  const [formikContextValues, setCheckoutValues] = useState<CheckoutFormikValues>()

  const [calculatedFees, setCalculatedFees] = useState<CalculateFeesResponse>()
  const [, setError] = useState()

  const attachNameToCalculatedFees = (
    calculatedFeesResponse: CalculateFeesResponse,
    values: CheckoutFormikValues,
  ): CalculateFeesResponse => {
    calculatedFeesResponse.products_with_fees = calculatedFeesResponse.products_with_fees.map(
      (productWithFee) => ({
        ...productWithFee,
        name: values.cards.find((el) => Number(el.id) === Number(productWithFee.product_pk))?.name,
      }),
    )

    return calculatedFeesResponse
  }

  const setCalculatedFeesWithNames = (
    calculatedFees: CalculateFeesResponse,
    manualFormValues?: CheckoutFormikValues,
  ) => {
    const values = manualFormValues || formikContextValues
    if (!values) return

    const feesWithNames = attachNameToCalculatedFees(calculatedFees, values)

    setCalculatedFees(feesWithNames)

    return feesWithNames
  }

  const getFeesFromBackend = async (manualFormValues?: CheckoutFormikValues) => {
    try {
      const values = manualFormValues || formikContextValues
      if (!values) return

      const { thirdResponse } = await threeStepsFeesEvaluator(values, mainKey)

      setCalculatedFeesWithNames(thirdResponse, values)
    } catch (e: unknown) {
      if (e instanceof TypeError)
        setError(() => {
          throw e
        })
      console.warn('[Calculate fees] Something went wrong getting fees', e)
    }
  }

  const items = formikContextValues?.cards.map(itemsMapper)

  const priceFromCards = formikContextValues?.cards.reduce((acc, cur) => {
    const { cardPrice } = calculateCardValueWithFee(
      cur.amount,
      cur.feePaymentOption,
      cur.feeFlat,
      cur.feePercentage,
      cur.denominatedValues,
    )

    return acc + cardPrice * cur.quantity
  }, 0)

  const balanceFromCards = formikContextValues?.cards.reduce((acc, cur) => {
    const { cardBalance } = calculateCardValueWithFee(
      cur.amount,
      cur.feePaymentOption,
      cur.feeFlat,
      cur.feePercentage,
      cur.denominatedValues,
    )

    return acc + cardBalance * cur.quantity
  }, 0)

  const paymentProcessingFee: PaymentProcessingFee | undefined =
    formikContextValues?.paymentMethod === 'creditcard'
      ? { type: 'factor', value: creditCardFactor }
      : undefined

  const amountFromChoiceCards = formikContextValues?.choiceCardsToUse.reduce(
    (acc, cur) => acc + cur.debit_value,
    0,
  )

  const choiceCardsCount = formikContextValues?.choiceCardsToUse.length
  const totalCardsCount = formikContextValues?.cards
    .map(({ quantity }) => quantity)
    .reduce((a, c) => a + c, 0)

  const getToPay = (currency?: string) => {
    if (!formikContextValues || !currency) return undefined

    return getSummaryValues({
      priceFromCards,
      amountFromChoiceCards,
      paymentProcessingFee,
      currency:
        formikContextValues.choiceCardsToUse[0]?.currency ?? formikContextValues.cards[0]?.currency,
    }).toPay
  }

  if (!formikContextValues)
    return (
      <CheckoutContext.Provider value={{ ...defaultCtx, setCheckoutValues: setCheckoutValues }}>
        {children}
      </CheckoutContext.Provider>
    )

  return (
    <CheckoutContext.Provider
      value={{
        amountFromChoiceCards,
        balanceFromCards,
        calculatedFees,
        choiceCardsCount,
        getFeesFromBackend,
        getToPay,
        items,
        paymentProcessingFee,
        priceFromCards,
        setCheckoutValues,
        totalCardsCount,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}
