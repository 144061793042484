import { colours } from '@configs/colours'
import styled from 'styled-components'

export const CheckboxInput = styled.input<{ $accent?: string; $error?: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  appearance: none;

  &[type='checkbox'] {
    border: 1px solid ${({ $error }) => ($error ? colours.error.red : colours.mist[200])};
  }

  &[type='checkbox']:checked {
    background-color: ${({ $accent = colours.brand.blue }) => $accent};
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml,<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.25 1L4.5 11L0.75 7.25" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  &[type='checkbox']:disabled {
    background-color: ${colours.mist[200]};
  }
`
