import { Form, Formik, FormikHelpers } from 'formik'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import * as y from 'yup'

import { AboutFooter } from '@components/atoms/About/About'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { BasicErrorComponent } from '@components/atoms/Error/Error'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import {
  ButtonTextLinkCSS,
  TitleHeaderH1LCSS,
  TitleHeaderH1SCSS,
} from '@components/atoms/typography/css'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { colours, gradients } from '@configs/colours'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { voucherApi } from '@services/voucherApi'
import { generatePath, useNavigate } from 'react-router-dom'
import { PATHS } from '@constants/paths'

const getValidationSchema = (t: TFunction) =>
  y.object({
    cardNumber: y.string().required(t('forms.validation.required')),
  })

const Page: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [errorDescription] = useState<string>()
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [claimedSuccessfully, setClaimedSuccessfully] = useState(false)

  const initialValues = { cardNumber: '' }

  const onSubmit = async (
    { cardNumber }: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ) => {
    try {
      setSubmitting(true)
      setErrorOccurred(false)
      setClaimedSuccessfully(false)

      const { status } = await voucherApi.essentVoucherClaim({ code: cardNumber })

      if (status === HttpCodes.get(HttpCodeNames.Ok)) {
        setClaimedSuccessfully(true)
      } else {
        setErrorOccurred(true)
      }
    } catch (e: unknown) {
      console.warn('[Essent activation] Something went wrong activating card', e)
      setErrorOccurred(true)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Root>
      <FlyingCards id="flying-cards">
        <PurpleCard />
        <OrangeCard />
        <BlueCard />
        <GreenCard />
      </FlyingCards>
      <Content>
        <WidthBoundary>
          <Grid>
            <Info>
              <Title>{t('applauseOnYourGift')}</Title>
              <Subtitle>{t('applauseOnYourGiftDescription')}</Subtitle>
            </Info>
            <FormFrame>
              <InputDescription>{t('receivedAnEssentGiftCardQuestion')}</InputDescription>
              <RegularGray>{t('enterCardNumberAndPinExplanation')}</RegularGray>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={getValidationSchema(t)}
              >
                {({ isSubmitting }) => {
                  return (
                    <WithTranslateFormErrors>
                      <StyledForm>
                        <CardNumberInput
                          autoComplete="cardNumber"
                          description={t('forms.fields.cardNumber')}
                          name="cardNumber"
                          placeholder={t('forms.placeholders.cardNumber')}
                          disabled={claimedSuccessfully}
                        />
                        <ButtonsWrapper>
                          {!isSubmitting ? (
                            <PrimaryButton
                              iconRight={claimedSuccessfully ? Icons.Checkmark : Icons.Gift}
                              type="submit"
                              disabled={claimedSuccessfully}
                            >
                              {t('forms.actions.activate')}
                            </PrimaryButton>
                          ) : (
                            <LoadingButton />
                          )}
                        </ButtonsWrapper>
                        {claimedSuccessfully && (
                          <SuccessMessage>{t('essentCardClaimedSuccessfully')}</SuccessMessage>
                        )}
                        {errorOccurred && (
                          <ErrorWrapper>
                            <BasicErrorComponent onClose={() => setErrorOccurred(false)}>
                              {errorDescription ? t(errorDescription) : t('somethingWentWrong')}
                            </BasicErrorComponent>
                          </ErrorWrapper>
                        )}
                        <BlueLinkWrapper>
                          <BlueLink onClick={() => navigate(generatePath(`/${PATHS.iHaveACard}`))}>
                            {t('ImNotAnEssentCustomer')}
                          </BlueLink>
                        </BlueLinkWrapper>
                      </StyledForm>
                    </WithTranslateFormErrors>
                  )
                }}
              </Formik>
            </FormFrame>
          </Grid>
        </WidthBoundary>
      </Content>
      <AboutFooter $noBackground />
    </Root>
  )
}

const BlueLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const BlueLink = styled.div`
  color: ${colours.brand.blue};
  font-size: 16px;
  ${ButtonTextLinkCSS};
  overflow-wrap: break-word;
  cursor: pointer;
  text-decoration: underline;
`

const ButtonsWrapper = styled.div`
  margin-bottom: 16px;
`

const SuccessMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${colours.plant[110]};
  text-align: center;
  ${BodyLargeCSS};
`

const Title = styled.div`
  margin-bottom: 16px;
  ${mediaQueries.from.breakpoint.desktop} {
    ${TitleHeaderH1LCSS}
  }
  ${mediaQueries.to.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }
`

const Subtitle = styled(Fonts.IntroSubtextLarge)`
  color: ${colours.mist[800]};
`

const InputDescription = styled(Fonts.TitleHeaderH2)`
  margin: 0 0 8px 0;
  text-align: left;
`

const RegularGray = styled(Fonts.BodyRegular)`
  color: ${colours.mist[900]};
  margin-bottom: 32px;
`

const Info = styled.div`
  ${mediaQueries.to.breakpoint.tablet} {
    width: min(440px, 96vw);
    text-align: center;
  }
`

const Grid = styled.div`
  display: grid;
  gap: 64px;
  justify-items: center;
  align-items: start;
  margin-bottom: 64px;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const CardNumberInput = styled(TextInput)`
  margin-bottom: 24px;
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 10;
  width: 100%;
  ${gradients.defaultBackground}
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 10;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 0;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-top: 20vh;
  }
`

const StyledForm = styled(Form)`
  width: 100%;
`

const ErrorWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
`

const FormFrame = styled.div`
  width: min(440px, 96vw);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 28px 24px 24px;
  gap: 32px;
  background: rgba(255, 255, 255, 0.85);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 24px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px);
`

const FlyingCards = styled.div`
  pointer-events: none;
  position: fixed;
  top: 20%;
  ${mediaQueries.from.breakpoint.desktop} {
    left: 60%;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    opacity: 0.4;
  }
`

const PurpleCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(249.02deg, rgba(92, 0, 186, 0.6) 0.07%, rgba(92, 0, 186, 0) 141.27%);
  border: 2px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.25))
    drop-shadow(1px 2px 12px rgba(0, 0, 0, 0.08));
  border-radius: 20px;
  transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0);
  width: 440px;
  height: 280px;
`

const OrangeCard = styled.div`
  position: absolute;
  top: 180px;
  left: -180px;
  width: 440px;
  height: 280px;
  background: linear-gradient(258deg, rgba(255, 85, 0, 0.6) 6.05%, rgba(255, 85, 0, 0) 102.94%);
  border: 2px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.25))
    drop-shadow(1px 2px 12px rgba(0, 0, 0, 0.08));
  border-radius: 20px;
  transform: matrix(0.98, 0.17, -0.17, 0.98, 0, 0);
`

const BlueCard = styled.div`
  position: absolute;
  top: 400px;
  left: 0;
  width: 340px;
  height: 221px;
  background: linear-gradient(316.2deg, rgba(0, 118, 255, 0.6) 38.03%, rgba(0, 118, 255, 0) 98.66%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0.503715px 0.503715px 0.503715px rgba(255, 255, 255, 0.25))
    drop-shadow(0.5px 1.00743px 6.04458px rgba(0, 0, 0, 0.08));
  border-radius: 14px;
  transform: matrix(-1, 0.1, -0.1, -1, 0, 0);
`

const GreenCard = styled.div`
  position: absolute;
  top: 600px;
  left: 120px;
  width: 220px;
  height: 145px;
  background: linear-gradient(
    194.77deg,
    rgba(40, 203, 162, 0.6) 26.85%,
    rgba(40, 203, 162, 0) 106.88%
  );
  border: 1.00743px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0.503715px 0.503715px 0.503715px rgba(255, 255, 255, 0.25))
    drop-shadow(0.503715px 1.00743px 6.04458px rgba(0, 0, 0, 0.08));
  border-radius: 14px;
  transform: matrix(-0.87, 0.5, -0.5, -0.87, 0, 0);
`

export default Page
