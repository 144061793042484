import styled from 'styled-components'

import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'

import { mediaQueries } from '@utils/mediaQueries'

import { generatePath, useNavigate } from 'react-router-dom'

import { refer } from '@configs/urls'

import { Banner } from '../tooltips/Banners/Banner'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { useTranslation } from 'react-i18next'
import { useSettingsContext } from '@hooks/useSettings'
import { localisedPriceFactory } from '@utils/getLocalisedPrice'

export const PanelIntrusion: React.FC = () => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const { panel } = useSettingsContext()

  const hasReward = !!panel?.reward_currency && !!panel?.reward_value
  const rewardValue = Number.isNaN(panel?.reward_value) ? undefined : Number(panel?.reward_value)

  const priceFormatter = localisedPriceFactory(i18n.language, panel?.reward_currency)
  const formattedPrice = priceFormatter(rewardValue)

  return (
    <Root>
      <Banner
        title={t('referFriend.title')}
        onCtaAction={() => navigate(generatePath(refer.root))}
        ctaText={t('referFriend')}
        icon={Icons.Gift}
      >
        {t('referFriend.description')}{' '}
        {hasReward && !!rewardValue && t('referFriendReward', { reward: formattedPrice })}
      </Banner>
    </Root>
  )
}

const Root = styled.div`
  padding: 16px;
  ${BodyRegularCSS}
  ${mediaQueries.to.breakpoint.mobileL} {
    flex-direction: column;
  }
`
